<template>
  <v-app>
    <v-main>
      <v-container
        fluid
        :fill-height="showLogin"
      >
        <v-row no-gutters>
          <v-col cols="12">
            <router-view :key="$route.fullPath" />
          </v-col>
        </v-row>
      </v-container>
      <v-container
        v-if="!showLogin"
        fluid
        class="pa-0"
      >
        <v-row no-gutters>
          <v-col cols="12">
            <navigation-menu />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavigationMenu from "@/components/NavigationMenu.vue";

export default {
  name: "App",
  components: {
    NavigationMenu
  },
  data: () => ({
    locale: false,
    showLogin: false,
  }),
  mounted() {
    this.onResize();
  },
  created() {
    if (this.$route.path === '/login') {
      this.showLogin = true;
    }
  },
  methods: {
    changeLocale() {
      this.$vuetify.lang.current = 'sk';
    },
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight};
    },
  },
};
</script>

<style lang="scss">

@import "scss/_variables.scss";
@import "scss/_global.scss";

#app * {
  font-family: $itaps-font-regular;
}

.v-application--wrap {
  min-height: 100% !important;
}

</style>

