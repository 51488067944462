<template>
  <v-card class="mb-4">
    <v-card-title class="d-flex justify-space-between">
      <div>
        {{ header }}
        <span
          v-if="clientName"
          class="subtitle-1 back-button"
          @click="showClientInvoices"
        >
          {{ clientName }}{{ clientOin ? " (" + clientOin + ")" : "" }}
        </span>
      </div>
      <div
        v-if="rightSubheader"
        class="justify-content-end"
      >
        <span
          class="subtitle-1"
          v-html="rightSubheader"
        />
      </div>
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  name: "HeaderCard",
  props: {
    header: {type: String, default: 'Header'},
    clientName: {type: String, default: null},
    clientOin: {type: String, default: null},
    rightSubheader: {type: String, default: ''}
  },
  data() {
    return {
      bold: true
    };
  },
  methods: {
    showClientInvoices() {
      if(this.clientOin) {
        this.$router.push({
          name: "InvoicesClient",
          params: { oin:  this.clientOin},
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables";
.back-button {
  transition: 0.2s;
  &:hover {
    cursor: pointer;
    color: $itaps-blue;
  }
}
.cursor-pointer {
  cursor: pointer;
}

.bold {
  font-weight: bolder;
}
</style>
