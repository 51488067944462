// src/plugins/vuetify.js

import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import VueI18n from 'vue-i18n';

import sk from 'vuetify/lib/locale/sk';
import en from 'vuetify/lib/locale/en';

Vue.use(Vuetify);
Vue.use(VueI18n);

const opts = {
    lang: {
        locales: { en, sk },
        current: 'sk',
    },
};

export default new Vuetify(opts);
