import Vue from "vue";
import App from "./App.vue";

import vuetify from "@/plugins/vuetify";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import i18n from "./i18n";
import VueMask from 'v-mask';
import authentication from './authentication';
import { store } from './store';

import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

const options = {
    timeout: 3500,
    position: POSITION.BOTTOM_RIGHT
};


Vue.use(Toast, options);

Vue.use(VueMask);
Vue.prototype.$http = axios;
axios.defaults.baseURL = env.VUE_APP_API || process.env.VUE_APP_API;
Vue.use(VueAxios, axios);
Vue.config.productionTip = false;

// eslint-disable-next-line no-unused-vars
authentication.initialize().then(_ => {
    new Vue({
        vuetify,
		store,
        router,
        i18n,
        render: (h) => h(App),
    }).$mount("#app");
});
