<script>
export default {
  parseClientStatus(status) {
    let statusesArray = status ? status.split(',') : [];
    let clientStatus = null;

    if (statusesArray.includes('STARTED')) {
      clientStatus = 'STARTED';
    } else if (statusesArray.includes('NEW')) {
      clientStatus = 'NEW';
    } else { // ACCEPTED, EXPORTED
      clientStatus = 'ACCEPTED';
    }

    return clientStatus;
  }
};
</script>
