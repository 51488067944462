<template>
  <v-row>
    <v-col cols="12">
      <!--      <v-card>-->
      <table-filters
        :items="checklists.accSystems"
        :contract-type-items="contractTypeItems"
        @change="onChangeFilter"
      />
      <!--      </v-card>-->
    </v-col>
    <v-col>
      <v-dialog
        v-model="dialogDelete"
        max-width="500px"
      >
        <v-card>
          <v-card-title class="text-h5">
            {{ $t('actions.delete') }}
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="red darken-1"
              text
              @click="closeDelete"
            >
              {{ $t('actions.cancel') }}
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="dialogDeleteConfirm"
            >
              {{ $t('actions.ok') }}
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogShowInvoice"
        max-width="32vw"
      >
        <v-card>
          <invoice-pdf
            v-if="!loadingPdf"
            :pdf="pdf"
            :pdf-name="pdfName"
            height="90vh"
          />
        </v-card>
      </v-dialog>
      <v-card>
        <div style="min-height: 4px;">
          <v-progress-linear
            v-model="extractionProgressValue"
            :active="showExtractionProgress"
            :indeterminate="false"
            :query="true"
          />
        </div>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="invoices"
          :search="search"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          mobile-breakpoint="900"
          item-key="id"
          show-select
          class="elevation-0 row-pointer"
          :loading="loading"
          :items-per-page="20"
          :footer-props="{'items-per-page-options':[20, 50, 100, -1]}"
          @current-items="getFiltered"
          @click:row="invoiceOpen"
        >
          <template #top>
            <v-toolbar
              flat
            >
              <div
                style="height: 100%;
                display: flex;
                align-items: center;"
              >
                {{ $t('invoices.list') }}
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                />
              </div>

              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('actions.search')"
                single-line
                hide-details
              />

              <div class="d-flex justify-content-end">
                <v-btn
                  class="ma-3"
                  color="bg-color bg-yellow ft-color ft-white"
                  :disabled="extractionDisabled"
                  @click="invoiceExtractor"
                >
                  {{ extractButtonName }}
                  <v-progress-circular
                    v-if="loadingExtraction"
                    class="ma-3"
                    indeterminate
                    color="white"
                  />
                </v-btn>
                <v-btn
                  v-if="!introPage"
                  class="ma-3"
                  color="bg-color bg-blue ft-color ft-white"
                  :disabled="disabledExport"
                  @click="createExportXml"
                >
                  {{ $t('actions.export') }}
                </v-btn>
                <v-btn
                  v-if="!introPage"
                  class="ma-3"
                  color="bg-color bg-dk-blue ft-color ft-white"
                  @click="appendInvoices"
                >
                  {{ $t('actions.insert.new') }}
                </v-btn>
              </div>
            </v-toolbar>
          </template>
          <template #item.contract_type="{ item }">
            {{ translateContractType(item.contract_type) }}
          </template>
          <template #item.name_contractor="{ item }">
            <v-chip
              v-if="clientOin && item.contractor_oin === clientOin"
              color="i-db"
            >
              {{ item.name_contractor }}
            </v-chip>
            <div v-else>
              {{ item.name_contractor }}
            </div>
          </template>
          <template #item.name_recipient="{ item }">
            <v-chip
              v-if="clientOin && item.recipient_oin === clientOin"
              color="i-db"
            >
              {{ item.name_recipient }}
            </v-chip>
            <div v-else>
              {{ item.name_recipient }}
            </div>
          </template>
          <template #item.status="{ item }">
            <v-chip
              :color="getColor(item.status)"
              dark
            >
              {{ translate(item.status) }}
            </v-chip>
          </template>
          <template #item.min_confidence="{ item }">
            <div>
              {{ parseFloat(item.min_confidence * 100).toFixed(2) + "%" }}
            </div>
          </template>
          <template #item.duplicated="{ item }">
            <v-chip v-if="item.duplicated">
              Duplikát s ID: {{ item.duplicated }}
            </v-chip>
          </template>

          <template #item.actions="{ item }">
            <div class="d-flex">
              <v-hover v-slot="{ hover }">
                <v-card
                  class="icon-card"
                  :elevation="hover ? 3 : 0"
                >
                  <v-icon
                    small
                    class="mr-2"
                    @click.stop.prevent="showInvoice(item)"
                  >
                    mdi-eye
                  </v-icon>
                </v-card>
              </v-hover>
              <v-hover v-slot="{ hover }">
                <v-card
                  class="icon-card"
                  :elevation="hover ? 3 : 0"
                >
                  <v-icon
                    small
                    class="pa-2"
                    @click.stop.prevent="deleteItem(item)"
                  >
                    mdi-delete
                  </v-icon>
                </v-card>
              </v-hover>
            </div>
          </template>
          <template #no-data>
            <v-btn
              color="primary"
              @click="clientOin ? getClientInvoices(clientOin) : getInvoices()"
            >
              {{ $t('actions.reset') }}
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import api from "@/resources/axios.json";
import axios from "axios";
import moment from "moment";
import TableFilters from "@/components/TableFilters";
import _ from "lodash";
import services from "@/services/ServiceMethods";
import StatusUtil from "@/utils/StatusUtil";
import InvoicePdf from "@/components/invoice/InvoicePdf";

export default {
  name: "InvoiceList",
  components: {
    TableFilters,
    InvoicePdf
  },
  data() {
    return {
      api: api,
      loading: true,
      loadingExtraction: false,
      loadingPdf: false,
      pdf: null,
      pdfName: null,
      introPage: false,
      dialog: false,
      dialogShowInvoice: false,
      search: '',
      dialogDelete: false,
      singleSelect: false,
      selected: [],
      contractTypeItems: [
        {id: this.$t("actions.all"), text: this.$t('actions.all')},
        {id: this.$t('invoices.type.vfa.name'), text: this.$t('invoices.type.vfa.lang')},
        {id: this.$t('invoices.type.pfa.name'), text: this.$t('invoices.type.pfa.lang')},
        {id: this.$t('invoices.type.not_sorted.name'), text: this.$t("invoices.type.not_sorted.lang")}
      ],
      headers: [
        {
          text: this.$t('invoices.table.id'),
          align: 'start',
          value: 'id',
        },
        {text: this.$t('invoices.table.contract'), value: 'contract'},
        {text: this.$t('invoices.table.contract_type'), value: 'contract_type'},
        {text: this.$t('invoices.table.internal_contract'), value: 'internal_contract'},
        {text: this.$t('invoices.table.contractor'), value: 'name_contractor'},
        {text: this.$t('invoices.table.recipient'), value: 'name_recipient'},
        {text: this.$t('invoices.table.status'), value: 'status'},
        {text: this.$t('invoices.table.min_confidence'), value: 'min_confidence'},
        {text: this.$t('invoices.table.acc_system'), value: 'acc_system'},
        {text: this.$t('invoices.table.created'), value: 'created'},
        {text: this.$t('invoices.table.duplicated'), value: 'duplicated'},
        {text: this.$t('actions.actions'), value: 'actions', sortable: false},
      ],
      invoices: [],
      editedIndex: -1,
      clientOin: this.$route.params.oin,
      sortBy: 'status',
      sortDesc: false,
      sortItems: [],
      disabledExport: true,
      checklists: this.$store.state.checklists,
      rangeSliderFilter: this.$store.state.filters.confidence.range,
      accSystemFilter: this.$store.state.filters.accSystem,
      contractTypeFilter: this.$store.state.filters.contractType,
      extractionDatesFilter: this.$store.state.filters.extractionDate,
      extractionDisabled: this.$store.state.extractionProgress.disabled,
      extractionProgressValue: this.$store.state.extractionProgress.progressValue,
      showExtractionProgress: this.$store.state.extractionProgress.show,
      currentProgress: this.$store.state.extractionProgress.currentProgress,
      totalProgress: this.$store.state.extractionProgress.totalProgress,
      interval: null,
      actualItem: null,
    };
  },
  computed: {
    extractButtonName() {
      return this.showExtractionProgress ? `${this.$t('actions.extractInProgress')}${this.currentProgress}/${this.totalProgress}` : this.$t('actions.extract');
    }
  },
  watch: {
    selected() {
      this.onChangeCheckbox(this.selected);
    },
    extractionDisabled(newValue) {
      this.$store.dispatch("setExtractionProgress", {key: "disabled", value: newValue});
    },
    currentProgress(newValue) {
      this.$store.dispatch("setExtractionProgress", {key: "currentProgress", value: newValue});
    },
    totalProgress(newValue) {
      this.$store.dispatch("setExtractionProgress", {key: "totalProgress", value: newValue});
    },
    extractionProgressValue(newValue) {
      this.$store.dispatch("setExtractionProgress", {key: "progressValue", value: newValue});
    },
    showExtractionProgress(newValue) {
      this.$store.dispatch("setExtractionProgress", {key: "show", value: newValue});
    }
  },
  created() {

    if (this.$route.name === 'Invoices') {
      this.introPage = true;
    }

    this.checkExtractionState();
    this.setupStream();

    if (this.clientOin) {
      this.getClient(this.clientOin);
      this.getClientInvoices(this.clientOin);
    } else {
      this.getInvoices();
    }
    this.getChecklists();
    this.loading = false;
  },
  destroyed() {
    clearInterval(this.interval);
  },
  methods: {
    async showInvoice(item) {
      this.loadingPdf = true;
      this.dialogShowInvoice = true;
      const response = await services.get(api.get.invoiceById + item.id);
      if(response) {
        this.pdf = response.data.file.file_encoded;
        this.pdfName = (response.data.generals && response.data.generals.contract_number) ? response.data.generals.contract_number.value : "12345678";
      }
      this.loadingPdf = false;
    },
    async checkExtractionState() {
      const response = await services.get(api.extractionProgress);
      let data = null;

      if(!response) {
        return null;
      } else {
        data = response.data;
      }

      this.currentProgress = data.shplock.current;
      this.totalProgress = data.shplock.total;

      if (data.shplock.lock === 1) {
        this.extractionDisabled = true;
        this.extractionProgressValue = (data.shplock.current / data.shplock.total) * 100;
        this.showExtractionProgress = true;
      } else {
        this.extractionDisabled = false;
        this.showExtractionProgress = false;
      }
      this.extractionDisabled = data.shplock.lock === 1;
    },
    deleteItem(item) {
      this.editedIndex = this.invoices.indexOf(item);
      this.dialogDelete = true;
      this.actualItem = item;
    },
    dialogDeleteConfirm() {
      this.invoices.splice(this.editedIndex, 1);
      services.delete(api.delete.invoice + this.actualItem.id);
      this.dialogDelete = false;
    },
    setupStream() {
      this.interval = setInterval(async () => {
        await this.checkExtractionState();
      }, 30000);
    },
    appendInvoices() {
      this.$router.push({name: 'InvoicesAppend'});
    },
    onChangeFilter(rangeSlider, accSystem, extractionDates, contractType) {
      this.rangeSliderFilter = rangeSlider;
      this.accSystemFilter = accSystem;
      this.extractionDatesFilter = extractionDates;
      this.contractTypeFilter = contractType;

      this.loading = true;
      this.invoices = [];
      if (this.clientOin) {
        this.getClientInvoices(this.clientOin);
      } else {
        this.getInvoices();
      }
      this.loading = false;
    },
    onChangeCheckbox(selected) {
      if (!selected || selected.length === 0) {
        this.disabledExport = true;
      } else {
        this.disabledExport = false;
        for (let item of selected) {
          if (item.status === "NEW") {
            this.disabledExport = true;
          }
        }
      }
    },
    async getChecklists() {
      this.checklists = await this.$store.getters.getChecklists();
    },
    getFiltered(e) {
      this.sortItems = [];
      for (const prop in e) {
        this.sortItems.push(e[prop].id);
      }
      this.$store.dispatch(`changeFilteredInvoices`, this.sortItems);

    },
    translate(v) {
      return this.$t('invoices.statuses.' + v);
    },
    translateContractType(v) {
      switch (v) {
        case 'income':
          return this.$t('invoices.type.pfa.lang');
        case 'expense':
          return this.$t('invoices.type.vfa.lang');
        default:
          return this.$t('invoices.type.not_sorted.lang');
      }
    },
    async getInvoicesApi(url, rangeSlider, accSystem, extractionDates, contracType) {
      let payload = "";
      let paramCounter = 0;

      if (rangeSlider) {
        let transformedRangeSlider = _.cloneDeep(rangeSlider);
        transformedRangeSlider.forEach((element, index) => {
          transformedRangeSlider[index] = element / 100;
        });
        payload += "?confidence=" + transformedRangeSlider;
        paramCounter++;
      }

      if (accSystem && accSystem !== this.$t("actions.all")) {
        if (paramCounter > 0) {
          payload += "&";
        } else {
          payload += "?";
        }
        payload += "accSystem=" + accSystem;
        paramCounter++;
      }

      if (extractionDates && extractionDates.length > 0) {
        if (paramCounter > 0) {
          payload += "&";
        } else {
          payload += "?";
        }
        payload += "extractionDate=" + extractionDates;
      }

      if (contracType && contracType != this.$t("actions.all")) {
        let contractTypeObj = this.contractTypeItems.find(item => item.text == contracType);

        if (contractTypeObj) {
          if (paramCounter > 0) {
            payload += "&";
          } else {
            payload += "?";
          }
          payload += "contractType=" + contractTypeObj.id;
        }
      }

      return await services.get(url + payload);
    },
    async getInvoices() {
      let response = await this.getInvoicesApi(api.get.allInvoicesLight, this.rangeSliderFilter, this.accSystemFilter, this.extractionDatesFilter, this.contractTypeFilter);

      if(!response) {
        return null;
      } else {
        response = response.data;
      }

      for (let item of response) {
        this.invoices.push({
          id: item.id,
          contract_type: item.generals && item.generals.contract_type ? item.generals.contract_type.value : "",
          contract: item.generals && item.generals.contract_number ? item.generals.contract_number.value : "",
          internal_contract: item.file_internal_name ? item.file_internal_name : "",
          name_contractor: item.contractor.name ? item.contractor.name.value : "",
          name_recipient: item.recipient.name ? item.recipient.name.value : "",
          status: item.status ? item.status.value : "",
          min_confidence: parseFloat(item.min_confidence).toFixed(2),
          acc_system: item.client && item.client.acc_system ? item.client.acc_system.account_system : "",
          created: this.date(item.created_at),
          duplicated: item.is_duplicate ? item.id_duplicate : ""
        });
      }
    },
    async getClient(clientOin) {
      const response = await services.get(api.get.clientsWithStatusById + clientOin);

      let client = null;
      let clientStatusData = null;

      if(!response) {
        return null;
      } else {
        client = response.data;
        clientStatusData = client.client_status;
      }

      await this.$store.dispatch(`setLatestClient`, client);
      this.$emit("changeClient", client.organization, client.oin);

      const clientStatus = StatusUtil.parseClientStatus(clientStatusData);
      this.$emit("changeClientStatus", this.$t('clients.statuses.' + clientStatus));
    },
    async getClientInvoices(clientOin) {
      let response = await this.getInvoicesApi(api.get.invoicesByClient + clientOin, this.rangeSliderFilter, this.accSystemFilter, this.extractionDatesFilter, this.contractTypeFilter);

      if(!response) {
        return null;
      } else {
        response = response.data;
      }

      for (let item of response) {
        this.invoices.push({
          id: item.id,
          contract_type: item.generals && item.generals.contract_type ? item.generals.contract_type.value : "",
          contract: item.generals && item.generals.contract_number ? item.generals.contract_number.value : "",
          internal_contract: item.file.file_internal_name ? item.file.file_internal_name : "",
          name_contractor: item.contractor.name ? item.contractor.name.value : "",
          contractor_oin: item.contractor.oin ? item.contractor.oin.value : "",
          name_recipient: item.recipient.name ? item.recipient.name.value : "",
          recipient_oin: item.recipient.oin ? item.recipient.oin.value : "",
          status: item.status ? item.status.value : "",
          min_confidence: item.min_confidence ? parseFloat(item.min_confidence).toFixed(2) : "",
          acc_system: item.client ? item.client.acc_system.account_system : "",
          created: item.created_at ? this.date(item.created_at) : "",
          duplicated: item.is_duplicate ? item.id_duplicate : ""
        });
      }
    },
    async invoiceExtractor() {
      this.loadingExtraction = true;

      if (this.clientOin) {
        await services.post(api.post.invoiceExtractorClient, {oin: this.clientOin});
        this.loadingExtraction = false;
      } else {
        await services.post(api.post.invoiceExtractor, null);
        this.loadingExtraction = false;
      }

      this.extractionDisabled = true;

    },
    async createExportXml() {
      let selectedAccSystems = {};

      let contractType = 2;
      if(this.selected[0].contract_type === "expense") {
        contractType = 1;
      } else if(this.selected[0].contract_type === "income") {
        contractType = 2;
      } else {
        contractType = 3;
      }

      for (let selectedItem of this.selected) {
        selectedAccSystems[selectedItem.acc_system] = {};
        selectedAccSystems[selectedItem.acc_system].ids = [];
      }

      for (let selectedItem of this.selected) {
        selectedAccSystems[selectedItem.acc_system].ids.push(selectedItem.id);
      }

      const headers = {
        'x-access-token': localStorage.getItem('adal.idtoken'),
        'Accept': 'application/json',
        responseType: 'blob'
      };

      await axios.post(api.post.export,
          {
            selected: selectedAccSystems,
            type: contractType,
            identity: this.$route.params.oin
          }, {headers}).then((response) => {

        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');

        fileLink.href = fileURL;
        if (Object.keys(selectedAccSystems).length === 1) {
          fileLink.setAttribute('download', Object.keys(selectedAccSystems)[0] + '_import.xml');
        } else if (Object.keys(selectedAccSystems).length > 1) {
          fileLink.setAttribute('download', 'imports.zip');
        }

        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    date: function (date) {
      return moment(date).format('DD. MM. YYYY, HH:mm:ss');
    },
    invoiceOpen(row) {
      this.$router.push({name: 'Invoice', params: {id: row.id, sortBy: this.sortBy, sortDesc: this.sortDesc}});
    },
    editItem(item) {
      this.editedIndex = this.invoices.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.invoices[this.editedIndex], this.editedItem);
      } else {
        this.invoices.push(this.editedItem);
      }
      this.close();
    },
    getColor(status) {
      if (status === 'NEW') return 'bg-color bg-blue';
      else if (status === 'ACCEPTED') return 'bg-color bg-yellow';
      else return 'bg-color bg-dk-blue';
    },
  }
};
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>

<style lang="scss" scoped>
@import "src/scss/_global.scss";

::v-deep .v-toolbar__content {
  justify-content: space-between;
}

button.v-icon {
  margin: 0 !important;
}
.icon-card {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 1.25rem;
  height: 1.25rem;
}
</style>
