import AuthenticationContext from 'adal-angular/lib/adal.js';

const config = {
  tenant:
    env.VUE_APP_AD_LOGIN_TENANT_ID || process.env.VUE_APP_AD_LOGIN_TENANT_ID,
  clientId:
    env.VUE_APP_AD_LOGIN_CLIENT_ID || process.env.VUE_APP_AD_LOGIN_CLIENT_ID,
  redirectUri:
    env.VUE_APP_AD_LOGIN_REDIRECT_URL ||
    process.env.VUE_APP_AD_LOGIN_REDIRECT_URL,
  cacheLocation:
    env.VUE_APP_AD_LOGIN_CACHE_LOCATION ||
    process.env.VUE_APP_AD_LOGIN_CACHE_LOCATION,
};

export default {
    authenticationContext: null,
    /**
     * @return {Promise}
     */
    initialize() {
        this.authenticationContext = new AuthenticationContext(config);

        // eslint-disable-next-line no-unused-vars
        return new Promise((resolve, reject) => {
            if (this.authenticationContext.isCallback(window.location.hash) || window.self !== window.top) {
                // redirect to the location specified in the url params.
                this.authenticationContext.handleWindowCallback();
            }
            else {
                // try pull the user out of local storage
                let user = this.authenticationContext.getCachedUser();

                if (user) {
                    resolve();
                }
                else {
                    // no user at all - go sign in.
                    this.signIn();
                }
            }
        });
    },
    /**
     * @return {Promise.<String>} A promise that resolves to an ADAL token for resource access
     */
    acquireToken() {
        return new Promise((resolve, reject) => {
            /**
             * azure active directory resource id -- VUE_APP_AD_LOGIN_CLIENT_ID>
             */
            const loginClientId =
              env.VUE_APP_AD_LOGIN_CLIENT_ID ||
              process.env.VUE_APP_AD_LOGIN_CLIENT_ID;

            this.authenticationContext.acquireToken(
            loginClientId,
            (error, token) => {
                if (error || !token) {
                return reject(error);
                } else {
                return resolve(token);
                }
            });
        });
    },
    /**
     * Issue an interactive authentication request for the current user and the api resource.
     */
    acquireTokenRedirect() {
       // this.authenticationContext.acquireTokenRedirect(process.env.VUE_APP_AD_LOGIN_OBJECT_ID);
        const loginClientId =
            env.VUE_APP_AD_LOGIN_CLIENT_ID ||
            process.env.VUE_APP_AD_LOGIN_CLIENT_ID;
        this.authenticationContext.acquireTokenRedirect(loginClientId);
    },
    /**
     * @return {Boolean} Indicates if there is a valid, non-expired access token present in localStorage.
     */
    isAuthenticated() {
        // getCachedToken will only return a valid, non-expired token.
        if (this.authenticationContext.getCachedToken(config.clientId)) { return true; }
        return false;
    },
    /**
     * @return An ADAL user profile object.
     */
    getUserProfile() {
        return this.authenticationContext.getCachedUser().profile;
    },
    signIn() {
        this.authenticationContext.login();
    },
    signOut() {
        this.authenticationContext.logOut();
    },
};