<template>
  <div :style="{width: width, height: height}">
    <div
      id="adobe-dc-view"
      style="width: 100%"
    />
    <component
      :is="'script'"
      type="application/javascript"
      :src="url"
    />
  </div>
</template>

<script>

export default {
  name: "InvoicePdf",
  props: {
    pdf: {
      type: String,
      default: 'Invoice',
    },
    pdfName: {
      type: String,
      default: 'Invoice',
    },
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "80vh"
    }
  },
  data() {
    return {
      adobe_key: env.VUE_APP_ADOBE_LICENSE_KEY || process.env.VUE_APP_ADOBE_LICENSE_KEY,
      url: env.VUE_APP_ADOBE_URL || process.env.VUE_APP_ADOBE_URL,
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    readPdf() {
      /* eslint no-undef: "off" */
      var adobeDCView = new AdobeDC.View({clientId: this.adobe_key, divId: "adobe-dc-view"});
      adobeDCView.previewFile({
            content: {
              location: {
                url: this.pdf
              }
            },
            metaData: {
              fileName: this.pdfName !== null ? this.pdfName : 'file'
            }
          },
          {
            defaultViewMode: "FIT_WIDTH",
            showAnnotationTools: true
          });
    },
    initialize() {
      document.addEventListener("adobe_dc_view_sdk.ready", this.readPdf);
    }
  }
};
</script>