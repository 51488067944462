<template>
  <v-expansion-panels
    v-if="!loading"
    v-model="panel"
    multiple
  >
    <v-expansion-panel>
      <v-expansion-panel-header>{{ name }}</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form
          v-if="!loading"
          ref="form"
        >
          <template v-for="(v, key) in obj">
            <v-card
              v-if="(!checkFromJson(key, 'visible')
                || (checkFromJson(key, 'visible')[$store.state.invoiceInformation.accountSystem.value]
                  && checkFromJson(key, 'visible')[$store.state.invoiceInformation.accountSystem.value].includes($store.state.invoiceInformation.contractType.value)))"
              :key="key"
              :class="{'ma-2': !obj[key].hidden, 'd-none': obj[key].hidden}"
              elevation="3"
            >
              <div
                v-if="!loading && !obj[key].hidden"
              >
                <div
                  class="d-flex"
                  style="border-bottom-left-radius: inherit; border-top-left-radius: inherit; width: 100%;"
                >
                  <div
                    :class="originalName === 'additional' || key === 'contract_type' || key === 'document_type' ? 'bg-color bg-blue' : v.confidence >= 0.9 || v.corrected_value ? 'success' : v.confidence >= 0.75 || v.corrected_value ? 'orange' : 'red darken-4'"
                    style="width: 0.5rem; border-bottom-left-radius: inherit; border-top-left-radius: inherit"
                  />
                  <v-row
                    no-gutters
                    class="d-flex align-center"
                    style="min-height: 2rem; width: 100%;"
                  >
                    <v-col
                      cols="12"
                      lg="4"
                      class="d-flex align-center px-3"
                    >
                      <div
                        class="text-button pt-0"
                        style="font-size: 1rem !important;"
                      >
                        {{ translate(key) }}
                      </div>

                      <v-tooltip
                        v-if="key === 'oin'"
                        top
                        nudge-bottom="10"
                        :type="oin.not_valid ? 'error' : 'success' "
                        :color="oin.not_valid ? 'orange' : 'green' "
                      >
                        <template #activator="{ on, attrs }">
                          <v-icon
                            class="mx-2"
                            v-bind="attrs"
                            :color="oin.not_valid ? 'orange' : 'green' "
                            v-on="on"
                          >
                            {{ oin.not_valid ? "mdi-alert" : "mdi-check-circle" }}
                          </v-icon>
                        </template>
                        <span>{{ oin.message }}</span>
                      </v-tooltip>

                      <v-tooltip
                        v-if="key === 'oi_vat'"
                        top
                        nudge-bottom="10"
                        :type="oi_vat.not_valid ? 'error' : 'success' "
                        :color="oi_vat.not_valid ? 'orange' : 'green' "
                      >
                        <template #activator="{ on, attrs }">
                          <v-icon
                            class="mx-2"
                            v-bind="attrs"
                            :color="oi_vat.not_valid ? 'orange' : 'green' "
                            v-on="on"
                          >
                            {{ oi_vat.not_valid ? "mdi-alert" : "mdi-check-circle" }}
                          </v-icon>
                        </template>
                        <span>{{
                          oi_vat.not_valid ? $t('validations.registers.oi_vat') + oi_vat.value + $t('validations.registers.oi_vat_negative') + oi_vat.country : $t('validations.registers.oi_vat') + oi_vat.value + $t('validations.registers.oi_vat_positive') + oi_vat.country
                        }}</span>
                      </v-tooltip>
                    </v-col>

                    <v-col
                      cols="12"
                      md="8"
                      :lg="originalName === 'additional' || key === 'contract_type' || key === 'document_type' ? 8 : 4"
                      class="px-3"
                    >
                      <v-combobox
                        v-if="originalName === 'additional' && obj[key].type === 'select-insert'"
                        v-model="obj[key].value"
                        v-mask="obj[key].mask"
                        class="pt-0 ma-0"
                        :disabled="obj[key].disabled"
                        :items="checklists[obj[key].items]"
                        item-title="text"
                        item-value="text"
                        :rules="checkRules(key)"
                        hide-details="auto"
                        no-data-text=""
                        @focus="
                          ($event) => {
                            $event.target.click();
                          }
                        "
                      />
                      <v-select
                        v-else-if="originalName === 'additional' && obj[key].type === 'select'"
                        v-model="obj[key].value"
                        v-mask="obj[key].mask"
                        class="pt-0 ma-0"
                        :disabled="obj[key].disabled"
                        :items="checklists[obj[key].items]"
                        item-title="text"
                        item-value="text"
                        return-object
                        :rules="checkRules(key)"
                        hide-details="auto"
                        no-data-text=""
                        @focus="
                          ($event) => {
                            $event.target.click();
                          }
                        "
                      />
                      <v-select
                        v-else-if="key === 'document_type'"
                        v-model.trim="document"
                        :items="selectDocuments"
                        single-line
                        class="pt-0 ma-0"
                        item-title="text"
                        item-value="text"
                        return-object
                        hide-details="auto"
                        @change="changeDocType"
                      />
                      <v-text-field
                        v-else-if="originalName === 'additional' && obj[key].type === 'text'"
                        v-model="obj[key].value"
                        v-mask="obj[key].mask"
                        class="text-left pl-2 pt-0 ma-0"
                        :type="obj[key].type"
                        :rules="checkRules(key)"
                        hide-details="auto"
                      />
                      
                      <v-select
                        v-else-if="select.includes(key)"
                        v-model.trim="documentFile"
                        :items="selectItems"
                        item-title="text"
                        item-value="id"
                        single-line
                        return-object
                        hide-details="auto"
                        class="text-left pl-2 pt-0 ma-0"
                        @change="moveFile"
                      />
                      
                      <v-menu
                        v-else-if="checkFromJson(key, 'type') === 'date'"
                        v-model="dateMenu[key]"
                        transition="scale-transition"
                        :close-on-content-click="false"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template #activator="{ on, attrs }">
                          <v-text-field
                            v-mask="checkFromJson(key, 'mask')"
                            :value="obj[key].value | formatDate"
                            class="pt-0 ma-0"
                            :rules="checkRules(key)"
                            hide-details="auto"
                            v-bind="attrs"
                            @input="(value) => (obj[key].value = value)"
                            @blur="onBlurDate(obj[key].value, key); updateItem(key, v.value, v.id)"
                            @click="onClickDate(obj[key].value, key)"
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          :value="dates[key]"
                          color="#1772BD"
                          no-title
                          @input="(value) => {obj[key].value = value; dates[key] = value; dateMenu[key] = false}"
                          @change="updateItem(key, v.value, v.id)"
                        />
                      </v-menu>
                      
                      <a
                        v-else-if="obj[key].type === 'url'"
                        :href="v.value"
                        target="_blank"
                        class="text-left pt-0 ma-0"
                        style="word-break: break-word;"
                      >{{ $t("invoices.extraction.url") }}</a>
                      
                      <v-text-field
                        v-else
                        :ref="'text-field-' + key"
                        v-model.trim="v.value"
                        v-mask="checkFromJson(key, 'mask')"
                        class="text-left pl-2 pt-0 ma-0"
                        :type="checkFromJson(key, 'type')"
                        :rules="checkRules(key)"
                        hide-details="auto"
                        @change="updateItem(key, v.value, v.id)"
                      />
                    </v-col>
                    <!-- </div> -->

                    <v-col
                      v-if="originalName !== 'additional' && key !== 'contract_type' && key !== 'document_type'"
                      class="d-flex align-center justify-end alert-col"
                      style="height: 100%;"
                      cols="12"
                      md="4"
                      lg="4"
                    >
                      <v-alert
                        v-if="originalName !== 'additional' && !select.includes(key)"
                        class="confidence cursor-check mb-0 py-1"
                        text
                        dense
                        :type="v.confidence >= 0.9 || v.corrected_value ? 'success' : v.confidence >= 0.75 || v.corrected_value ? 'error' : 'error'"
                        :color="v.confidence >= 0.9 || v.corrected_value ? 'green' : v.confidence >= 0.75 || v.corrected_value ? 'orange' : 'red darken-4'"
                        @click="v.corrected_value = 'manual'"
                      >
                        {{ badgeValue(v) }}
                      </v-alert>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-card>
          </template>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import api from "@/resources/axios.json";
import {checkVAT, countries} from "jsvat";
import services from "@/services/ServiceMethods";
import skLang from "@/locales/sk.json";

export default {
  name: "InvoicePanelSection",
  filters: {
    formatDate: (date) => {
      if (!date) return null;

        if (date.includes("-")) {
          return date.split('-').reverse().join(".");
        }  else {
          return date;
        }
    }
  },
  props: {
    section: {
      type: Object,
      default: () => null,
    },
    name: {
      type: String,
      default: () => 'name',
    },
    originalName: {
      type: String,
      default: () => 'name',
    },
    select: {
      type: Array,
      default: () => []
    },
    selectItems: {
      type: [Array, Object],
      default: () => {
      }
    },
    selectDocuments: {
      type: [Array, Object],
      default: () => {
      }
    },
    invoiceData: {
      type: Object,
      default: () => {
      }
    },
    checklists: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      dates: {},
      dateMenu: {},
      document: null,
      documentFile: null,
      oi_vat: {
        not_valid: false,
        value: "",
        country: ""
      },
      oin: {
        not_valid: false,
        message: ""
      },
      loading: true,
      obj: this.section,
      panel: [0, 1],
      checkbox: true,
      colors: [],
      items: [],
      rules: {
        required: v => !!v || this.$t('validations.required'),
        counter(n, self) {
          return v => (v && v.length || !v) <= n || self.$tc("validations.max_characters", n);
        },
        email: v => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return (!v || pattern.test(v)) || this.$t('validations.email');
        },
        date: v => {
          const pattern = /^([0]?[1-9]|[1|2][0-9]|[3][0|1])[./-]([0]?[1-9]|[1][0-2])[./-]([0-9]{4})$/;
          return (!v || pattern.test(v)) || this.$t('validations.date');
        }
      },
    };
  },
  watch: {
    section: {
      handler() {
        if (this.section) {
          this.obj = this.section;
        }
        this.$forceUpdate();
      },
      deep: true
    },
  },
  async created() {
    this.loading = true;

    //TODO REFACTOR
    if (this.originalName === 'generals') {

      this.document = this.selectDocuments[0];
      this.documentFile = this.selectItems[2];

      let docType = null;
      if (this.$store.state.invoiceInformation.documentType) {
        docType = this.$store.state.invoiceInformation.documentType.value;
      }

      if (docType !== null) {
        for (let i = 0; i < this.selectDocuments.length; i++) {
          if (this.selectDocuments[i].id === docType) {
            this.document = this.selectDocuments[i];
          }
        }
      }
      this.changeDocType();

      let docTypeFile = null;
      if (this.$store.state.invoiceInformation.contractType) {
        docTypeFile = this.$store.state.invoiceInformation.contractType.value;
      }

      if (docTypeFile !== null) {
        for (let j = 0; j < this.selectItems.length; j++) {
          if (this.selectItems[j].id === docTypeFile) {
            this.documentFile = this.selectItems[j];
          }
        }
      }
    }


    if (this.obj !== null) {

      if (this.obj.oin) {
        await this.validateOIN(this.obj.oin.value);
      }

      if (this.obj.oi_vat) {
        await this.validateVAT(this.obj.oi_vat.value);
      }

      if (this.obj.date_delivery) {
        this.obj.date_delivery.value = await this.checkDate(this.obj.date_delivery.value);
        await this.updateItem('date_delivery', this.obj.date_delivery.value, this.obj.date_delivery.id);
      }

      if (this.obj.date_due) {
        this.obj.date_due.value = await this.checkDate(this.obj.date_due.value);
        await this.updateItem('date_due', this.obj.date_due.value, this.obj.date_due.id);
      }

      if (this.obj.date_issue) {
        this.obj.date_issue.value = await this.checkDate(this.obj.date_issue.value);
        await this.updateItem('date_issue', this.obj.date_issue.value, this.obj.date_issue.id);
      }
    }
    this.loading = false;
    this.$emit('loaded', this.originalName);
  },
  methods: {
    onClickDate(value, key) {
      const regex = /^([0]?[1-9]|[1|2][0-9]|[3][0|1]|[0-9]{4})[./-]([0]?[1-9]|[1][0-2])[./-]([0-9]{4}|[0-9]{2})$/;

      if (regex.test(value)) {
        this.onBlurDate(value, key);
      }
    },
    onBlurDate(value, key) {
      if(value && value.includes(".")) {
        this.dates[key] = value.split(".").reverse().join("-");
      } else {
        this.dates[key] = value;
      }
    },
    parseDate(date) {
      if (!date) return null;
      return date.split('-').reverse().join(".");
    },
    formatDateToDatabase(date) {
      if (!date) return null;
      return date.split('.').reverse().join("-");
    },
    moveFile() {
      let docTypeFile = null;
      switch (this.documentFile) {
        case 'income' :
          docTypeFile = 1;
          break;
        case 'expense':
          docTypeFile = 2;
          break;
        default:
          docTypeFile = 3;
          break;
      }

      services.post(api.post.updateFile + docTypeFile, {
        id: this.$route.params.id,
        folder: this.documentFile.id
      });
      this.$emit("file-moved", this.documentFile);
    },
    changeDocType() {
      services.put(api.put.documentType + this.$route.params.id, {
        document_type: this.document.id
      }, false);
    },
    badgeValue(v) {
      if (v.corrected_value === 'manual') {
        return this.$t('actions.corrected_check');
      } else if (v.corrected_value !== null) {
        return this.$t('actions.corrected') + ", " + "\n" + this.percentage(v.confidence);
      } else if (v.mined_value === null) {
        return this.$t('actions.notMined');
      } else {
        return this.percentage(v.confidence);
      }
    },
    async validate(k, v) {
      if (k === 'oin') {
        await this.validateOIN(v);
      }
      if (k === 'oi_vat') {
        await this.validateVAT(v);
      }
    },
    async validateVAT(oi_vat) {
      ('countries');
      const vatResult = await checkVAT(oi_vat, countries); // check against all supported countries

      this.oi_vat.not_valid = !vatResult.isValid;

      if (vatResult.country) {
        this.oi_vat.country = vatResult.country.isoCode.short;
        this.oi_vat.value = vatResult.value;
      }
    },
    async validateOIN(oin) {
      let response = await services.get(api.get.registerOin + oin);

      if (!response) {
        return null;
      } else {
        response = response.data;
      }

      if (response) {
        if (response.results.length === 0) {
          this.oin.not_valid = true;
          this.oin.message = this.$t('validations.registers.oin_negative');

        } else {
          this.oin.not_valid = false;
          this.oin.message = this.$t('validations.registers.oin_positive');
        }

      } else {
        this.oin.not_valid = true;
        this.oin.message = this.$t('validations.registers.not_available');
      }

      if (!this.oin.not_valid) {
        this.loadClientByOin();
      }
    },
    async loadClientByOin() {
      if (this.obj && this.obj.oin.value) {
        const response = await services.get(api.get.clientsById + this.obj.oin.value);
        let clientByOin = null;

        if (!response) {
          return null;
        } else {
          clientByOin = response.data;
        }

        let clientParamKeys = ['address_street', 'address_postcode', 'address_city', 'country', /*'iban',*/ 'oi_vat'];
        // client can have more than one iban

        if (this.originalName === 'contractor') {
          clientParamKeys.push('email', 'phone');
        }

        if (clientByOin['organization']) {
          this.updateItem('name', clientByOin['organization'], this.obj.name.id);
        }

        if (clientByOin['vat']) {
          this.updateItem('vat_number', clientByOin['vat'], this.obj.vat_number.id);
        }

        clientParamKeys.forEach(clientParamKey => {
          if (clientByOin[clientParamKey]) {
            this.updateItem(clientParamKey, clientByOin[clientParamKey], this.obj[clientParamKey].id);
          }
        });
      }
    },
    async updateItem(k, v, id) {
      let response = await services.put(api.put.updateVal + id, {
        'corrected_value': this.invoiceData[k].type === "date" ? this.formatDateToDatabase(v) : v,
        'status': 3,
        'invoice': this.$route.params.id,
        'user_id': this.$store.state.user.oid,
      }, false);

      if (!response) {
        return null;
      } else {
        response = response.data;
      }

      this.$emit('changeObject', {data: response, item: this.originalName});

      if (k === 'oin') {
        this.$emit("change:oin");
      }

      if (k === 'date_delivery') {
        this.$emit("change:dateDelivery");
      }

      await this.validate(k, v);
    },
    translate(v) {
      if (this.$store.state.invoiceInformation.accountSystem) {
        const accountSystem = this.$store.state.invoiceInformation.accountSystem.value;
        if (skLang.invoices.extraction[v] && skLang.invoices.extraction[v][accountSystem]) {
          if (this.$store.state.invoiceInformation.contractType) {
            const contractType = this.$store.state.invoiceInformation.contractType.value;
            if (skLang.invoices.extraction[v][accountSystem][contractType]) {
              return this.$t('invoices.extraction.' + v + "." + accountSystem + "." + contractType);
            }
          }
          return this.$t('invoices.extraction.' + v + "." + accountSystem);
        } else {
          if (this.$store.state.invoiceInformation.contractType) {
            const contractType = this.$store.state.invoiceInformation.contractType.value;
            if (skLang.invoices.extraction[v] && skLang.invoices.extraction[v][contractType]) {
              return this.$t('invoices.extraction.' + v + "." + contractType);
            }
          }
        }
      } else if (this.$store.state.invoiceInformation.contractType) {
        const contractType = this.$store.state.invoiceInformation.contractType.value;
        if (skLang.invoices.extraction[v][contractType]) {
          return this.$t('invoices.extraction.' + v + "." + contractType);
        }
      }
      return this.$t('invoices.extraction.' + v);
    },
    percentage(v) {
      if (v !== undefined) {
        return this.$t('invoices.extraction.confidence') + ' ' + parseFloat(v * 100).toFixed(2) + '%';
      }
    },
    checkFromJson(k, attr) {
      for (let i = 0; i < Object.keys(this.invoiceData).length; i++) {
        if (k === Object.keys(this.invoiceData)[i]) {
          return Object.values(this.invoiceData)[i][attr];
        }
      }
    },
    checkRules(k) {

      let rules = [];

      for (let i = 0; i < Object.keys(this.invoiceData).length; i++) {

        if (k === Object.keys(this.invoiceData)[i]) {

          let rv = (Object.values(this.invoiceData)[i].rules);
          for (let j = 0; j < rv.length; j++) {

            let rvj = Object.values(this.invoiceData)[i].rules[j];

            if (rvj.toString() === 'required') {
              rules.push(this.rules.required);
            } else if (rvj.toString().startsWith('counter')) {
              let chars = rvj.split('-');
              this.rules.counter(parseInt(chars[1]), this);
            } else if (rvj.toString() === 'email') {
              rules.push(this.rules.email);
            } else if (rvj.toString() === 'date') {
              rules.push(this.rules.date);
            } else {
              console.error(`Rule "${rvj}" is not defined! Add an exception to the method "checkRules".`);
            }
          }
        }
      }
      return rules;
    },
    async checkDate(date) {

      if (date) {
        let dateParts = date.split('-');

        dateParts = dateParts.map(element => {
          return element.trim();
        });

        let resultDate = "";
        if (dateParts[0].length === 4) {
          let reversedDateParts = dateParts.reverse();
          for (const part in reversedDateParts) {
            resultDate += reversedDateParts[part];
          }
        }

        for (let i = 0; i < dateParts.length; i++) {

          let dateFixed = "0";
          if (dateParts[i].length === 1) {
            dateFixed += dateParts[i];
            dateParts[i] = dateFixed;
          }
        }

        resultDate = dateParts.join('.');

        return resultDate;
      } else {
        return date;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.cursor-check {
  cursor: pointer;
}

::v-deep {
  .v-alert.confidence {
    width: 100%;
    font: {
      size: 0.6rem !important;
    };
    height: 100%;
    display: table;
    padding: {
      left: 0;
      right: 0.5rem;
    }
    border: {
      bottom-left-radius: 0;
      top-left-radius: 0;
    }


    i {
      display: inherit;
      vertical-align: inherit;
      padding: 0 0.5rem;
    }

    .v-alert__content {
      display: inherit;
      vertical-align: inherit;
    }

    .v-alert__wrapper {
      display: table-cell;
      vertical-align: middle;
    }
  }

  .v-input {
    padding-left: 0 !important;
  }

  input, .v-select__selection {
    padding: {
      top: 0 !important;
      bottom: 0 !important;
    }
    margin: {
      top: 0 !important;
      bottom: 0 !important;
    }
  }

  .v-select {
    margin: {
      top: -0.25rem !important;
    };
  }

  .v-input__append-inner {
    margin: {
      top: 0 !important;
    }
  }

  .v-expansion-panel-header {
    font-size: 1.25rem;
    font-weight: 500;
    text-transform: uppercase;
    font-family: 'Roboto' !important;
  }

  .v-expansion-panel-content__wrap {
    padding: 0 !important;

  }
}

.additional {
  ::v-deep .v-input--selection-controls__input {
    display: none;
  }

  ::v-deep label {
    cursor: default;
  }
}

.v-tooltip__content {
  opacity: 1 !important;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.alert-col {
  @media screen and (max-width: 1263px) and (min-width: 960px) {
    margin-top: -2.25rem;
  }
  @media screen and (max-width: 959px) {
    height: initial !important;
  }
}
</style>
