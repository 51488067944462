<script>
import axios from "axios";
import {store} from "@/store";
import i18n from '@/i18n';
import Vue from "vue";

const headers = {
  'x-access-token': localStorage.getItem('adal.idtoken'),
  'Accept': 'application/json'
};

export default {
  async getChecklist(url, name) {
    try {
      await axios.get(url, {headers}).then(response => {
        store.dispatch(`setChecklist`, {key: name, data: response.data, status: response.status});
      })
      .catch(error => {
        this.handleGetError(error);
      });
    } catch (e) {
      console.error(e);
    }
  },
  async get(url) {
    try {
      return await axios.get(url, {headers})
      .catch(error => {
        this.handleGetError(error);
      });
    } catch (e) {
      console.error(e);
    }
  },
  async post(url, body = null, alert = true) {
    try {
      return await axios.post(url, body, {headers}).then((response) => {
        if(alert) {
          Vue.$toast(i18n.t('actions.errorHandle.successPost'), {
            toastClassName: "success-class",
          });
        }
        
        return response;
      })
      .catch(error => {
        this.handlePostError(error);
      });
    } catch (e) {
      console.error(e);
    }
  },
  async put(url, body, alert) {
    try {
      return await axios.put(url, body, {headers}).then(response => {
        if (alert) {
          Vue.$toast(i18n.t('actions.errorHandle.successPut'), {
            toastClassName: "success-class",
          });
        }
        return response;
      })
      .catch(error => {
        this.handlePutError(error);
      });
    } catch (e) {
      console.error(e);
    }
  },
  async delete(url) {
    try {
      return await axios.delete(url, {headers}).then(response => {
        Vue.$toast(i18n.t('actions.errorHandle.successDelete'), {
          toastClassName: "success-class",
        });
        return response;
      })
      .catch(error => {
        this.handleDeleteError(error);
      });
    } catch (e) {
      console.error(e);
    }
  },

  handleGetError(error) {
    // Vue.$toast(i18n.t('actions.errorHandle.errorGet'), {
    //   toastClassName: "danger-class",
    // });
    console.error(error);
  },
  handlePostError(error) {
    // Vue.$toast(i18n.t('actions.errorHandle.errorPost'), {
    //   toastClassName: "danger-class",
    // });
    console.error(error);
  },
  handlePutError(error) {
    // Vue.$toast(i18n.t('actions.errorHandle.errorPut'), {
    //   toastClassName: "danger-class",
    // });
    console.error(error);
  },
  handleDeleteError(error) {
    // Vue.$toast(i18n.t('actions.errorHandle.errorDelete'), {
    //   toastClassName: "danger-class",
    // });
    console.error(error);
  },
};
</script>

<style lang="css">
.Vue-Toastification__toast--default.danger-class {
  background-color: darkred !important;
}

.Vue-Toastification__toast--default.success-class {
  background-color: darkgreen !important;
}
</style>
