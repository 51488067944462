<template>
  <div>
    <header-card
      :header="$t('menu.invoices')"
      :client-name="clientName"
      :client-oin="clientOin"
      :right-subheader="rightSubheader"
    />
    <router-view
      :key="$route.fullPath"
      @changeClient="changeClient"
      @changeClientStatus="changeClientStatus"
    />
  </div>
</template>

<script>
import HeaderCard from "@/components/HeaderCard.vue";

export default {
  name: "InvoiceDetection",
  components: {
    HeaderCard
  },
  data() {
    return {
      clientName: null,
      clientOin: null,
      rightSubheader: null
    };
  },
  methods: {
    changeClient(clientName, clientOin) {
      this.clientName = clientName;
      this.clientOin = clientOin;
    },
    changeClientStatus(status) {
      this.rightSubheader = this.$t('invoices.statuses.status') + ': ' + status;
    },

  }
};

</script>
