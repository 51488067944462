import Vue from "vue";
import VueRouter from "vue-router";

import InvoiceDetection from "@/views/InvoiceDetection.vue";
import InvoiceList from "@/components/invoice/InvoiceList";
import InvoiceCard from "@/components/invoice/InvoiceCard";
import InvoicesAppend from "@/components/invoice/InvoicesAppend";

import ClientsDatabase from "@/views/ClientsDatabase";
import ClientList from "@/components/clients/ClientList";
import ClientCard from "@/components/clients/ClientCard";


import CompaniesDatabase from "@/views/CompaniesDatabase";
import CompanyList from "@/components/companies/CompanyList";
import CompanyCard from "@/components/companies/CompanyCard";

//import LoginView from "@/views/LoginView";

Vue.use(VueRouter);

import authentication from '../authentication';

const routes = [
    {
        path: "/",
        redirect: { name: 'Invoices' },
    },
    {
        path: "/invoice-detection",
        name: "Invoice Detection",
        component: InvoiceDetection,
        redirect: { name: 'Invoices' },
        meta: {
            requiresAuthentication: true
        },
        children: [
            {
                path: 'invoices',
                name: "Invoices",
                component: InvoiceList,
            },
            {
                path: 'invoices/:id/:sortBy/:sortDesc',
                name: "Invoice",
                component: InvoiceCard
            },
            {
                path: 'invoices/:id',
                name: "Invoice",
                component: InvoiceCard,
            },
            {
                path: 'invoices-client/:oin',
                name: "InvoicesClient",
                component: InvoiceList
            },
            {
                path: 'invoices-append/',
                name: "InvoicesAppend",
                component: InvoicesAppend
            },
        ]
    },
    {
        path: "/client-list",
        name: "Client List",
        redirect: { name: 'Clients' },
        component: ClientsDatabase,
        meta: {
            requiresAuthentication: true
        },
        children: [
            {
                path: 'clients',
                name: "Clients",
                component: ClientList
            },
            {
                path: 'clients/:id',
                name: "Client",
                component: ClientCard,
            },
        ]
    },
    {
        path: "/company-list",
        name: "Company List",
        redirect: { name: 'Companies' },
        component: CompaniesDatabase,
        meta: {
            requiresAuthentication: true
        },
        children: [
            {
                path: 'companies',
                name: "Companies",
                component: CompanyList
            },
            {
                path: 'companies/:id',
                name: "Company",
                component: CompanyCard
            },
        ]
    },
    /*{
        path: "/login",
        name: "Login",
        component: LoginView,
    },*/
];

const router = new VueRouter({
    mode: "history",
    routes,
});

// Global route guard
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuthentication)) {
        // this route requires auth, check if logged in
        if (authentication.isAuthenticated()) {
            // only proceed if authenticated.
            next();
        } else {
            authentication.signIn();
        }
    } else {
        next();
    }
});

export default router;
