<template>
  <v-card
    class="mx-auto"
    min-height="88vh"
  >
    <v-toolbar
      dark
      color="primary"
    >
      <v-text-field
        v-model="search"
        class="ma-2"
        :label="$t('actions.search')"
        dark
        flat
        solo-inverted
        hide-details
        clearable
        clear-icon="mdi-close-circle-outline"
      />
    </v-toolbar>
    <v-row>
      <v-col>
        <v-card-text>
          <v-treeview
            v-if="!loading"
            v-model="tree"
            style="cursor: pointer"
            activatable
            selectable
            color="primary"
            selected-color="indigo"
            return-object
            open-on-click
            transition
            :multiple-active="false"
            :items="items"
            :search="search"
            expand-icon="mdi-chevron-down"
            indeterminate-icon="mdi-bookmark-minus"
            :open.sync="openNodes"
            @update:active="activateTreeItem"
          >
            <template #prepend="{ item }">
              <v-icon>
                {{ item.children ? `mdi-folder-network` : `mdi-file` }}
              </v-icon>
            </template>
          </v-treeview>
        </v-card-text>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-card-text>
          <div
            v-if="tree.length === 0"
            key="title"
            class="text-h6 font-weight-light grey--text pa-4 text-center"
          >
            {{ $t('actions.select') }}
          </div>

          <v-scroll-x-transition
            group
            hide-on-leave
          >
            <v-chip
              v-for="(selection, i) in tree"
              :key="i"
              color="grey"
              dark
              small
              class="ma-1"
            >
              <v-icon
                left
                small
              >
                mdi-beer
              </v-icon>
              {{ selection.name }}
            </v-chip>
          </v-scroll-x-transition>
        </v-card-text>
      </v-col>
    </v-row>
    <v-divider />
    <v-card-actions>
      <v-btn
        color="bg-color bg-blue ft-color ft-white"
        @click="tree = []"
      >
        {{ $t('actions.reset') }}
      </v-btn>

      <v-spacer />

      <v-btn
        color="bg-color bg-yellow ft-color ft-white"
        @click="uploadInvoice"
      >
        {{ $t('actions.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import services from "@/services/ServiceMethods";
import api from "@/resources/axios.json";

export default {
  name: "InvoiceCard",
  data: () => ({
    openNodes: [],
    api: api,
    selected: null,
    tree: [],
    types: [],
    open: [],
    search: null,
    caseSensitive: false,
    documentStructure: null,
    documentTree: [],
    loading: true,
    active: [],
    items: [{
      id: 0,
      name: "",
      children: []
    }],
    treeIdIterator: 1,
    result: [],
    sharepointPath: "/"
  }),
  computed: {
    filter() {
      return this.caseSensitive
          ? (item, search, textKey) => item[textKey].indexOf(search) > -1
          : undefined;
    },
  },
  created() {
    this.items[0].name = this.$store.state.client.folder_path;
    this.$emit("changeClient", this.$store.state.client.organization, this.$store.state.client.oin);
    this.activateTreeItem(this.items);
    this.loading = false;
  },
  methods: {
    findParents(arr, id) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id === id) {
          return [];
        } else if (arr[i].children && arr[i].children.length) {

          let t = this.findParents(arr[i].children, id);

          if (t !== false) {
            t.push(arr[i].name);

            return t;
          }
        }
      }
      return false;
    },
    async activateTreeItem(item) {

      if (item.length !== 0) {
        if (item[0].children === undefined) {
          return false;
        }

        this.sharepointPath = '/' + item[0].name;
        if (this.sharepointPath === '/') {
          this.sharepointPath = "";
        } else {
          let pathParts = this.findParents(this.$store.state.documentTree, item[0].id);
          this.sharepointPath = "/";
          for (let i = pathParts.length - 1; i >= 0; i--) {
            this.sharepointPath += '/' + pathParts[i];
          }
          this.sharepointPath += '/' + item[0].name;
        }

        const response = await services.post(api.post.documentStructure, {path: this.sharepointPath}, false);
        let documentTree = response ? response.data : null;
        item[0].children = this.createDocumentStructure(documentTree);
        await this.$store.dispatch(`setDocumentTree`, this.items);
        this.sharepointPath = "";

        this.openNodes.push(item[0]);
      }
    },
    createDocumentStructure(structure) {

      let struct = [];
      let it = 0;

      if (structure !== undefined) {
        for (let j = 0; j < structure.folders.length; j++) {
          struct[it] = {id: this.treeIdIterator, name: structure.folders[j], children: []};
          this.treeIdIterator++;
          it++;
        }

        for (let k = 0; k < structure.files.length; k++) {
          struct[it] = {id: this.treeIdIterator, name: structure.files[k]};
          this.treeIdIterator++;
          it++;
        }

        if (structure.folders.length === 0) {
          return struct;
        }

        return struct;
      } else {
        return [];
      }
    },
    async uploadInvoice() {

      let oin = this.$store.state.client.oin;
      let emptyInvoiceStatus = null;

      for (let i = 0; i < this.tree.length; i++) {
        if (this.tree[i].children === undefined) {
          let pathParts = this.findParents(this.$store.state.documentTree, this.tree[i].id);

          this.sharepointPath = "/";
          for (let i = pathParts.length - 1; i >= 0; i--) {
            this.sharepointPath += '/' + pathParts[i];
          }
          this.sharepointPath += '/' + this.tree[i].name;

          let path = this.sharepointPath;
          let response = await services.post(api.post.emptyInvoice, {path: path, oin: oin});
          emptyInvoiceStatus = response ? response.status : null;
        }
      }

      if (emptyInvoiceStatus === 200) {
        await this.$router.push({path: `/invoice-detection/invoices-client/${oin}`});
      }
    }
  }
  ,
}
;
</script>