var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('v-card',{staticClass:"pa-4 mr-md-2 text-center"},[_c('label',{attrs:{"for":"min-confidence"}},[_vm._v(_vm._s(_vm.$t("invoices.table.min_confidence")))]),_c('v-range-slider',{staticClass:"align-center mt-5",attrs:{"id":"min-confidence","max":_vm.maxSlider,"min":_vm.minSlider,"hide-details":"","color":"#1772BD"},on:{"change":_vm.rangeSliderOnChange},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-text-field',{staticClass:"mt-0 pt-0",staticStyle:{"width":"60px"},attrs:{"value":_vm.rangeSlider[0],"hide-details":"","single-line":"","type":"number"},on:{"change":function($event){_vm.$set(_vm.rangeSlider, 0, $event); _vm.rangeSliderOnChange();}}})]},proxy:true},{key:"append",fn:function(){return [_c('v-text-field',{staticClass:"mt-0 pt-0",staticStyle:{"width":"60px"},attrs:{"value":_vm.rangeSlider[1],"hide-details":"","single-line":"","type":"number"},on:{"change":function($event){_vm.$set(_vm.rangeSlider, 1, $event); _vm.rangeSliderOnChange();}}})]},proxy:true}]),model:{value:(_vm.rangeSlider),callback:function ($$v) {_vm.rangeSlider=$$v},expression:"rangeSlider"}})],1)],1),_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('v-card',{staticClass:"pa-4 ml-md-2 mr-md-2 text-center my-5 my-md-0"},[_c('label',{attrs:{"for":"acc-system"}},[_vm._v(_vm._s(_vm.$t("invoices.table.acc_system")))]),_c('v-select',{staticClass:"mt-5",attrs:{"id":"acc-system","color":"#1772BD","items":_vm.itemsProp,"item-title":"text","item-value":"text","no-data-text":"","hide-details":""},on:{"focus":function ($event) {
              $event.target.click();
            },"change":_vm.selectOnChange},model:{value:(_vm.selectContent),callback:function ($$v) {_vm.selectContent=$$v},expression:"selectContent"}})],1)],1),_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('v-card',{staticClass:"pa-4 ml-md-2 text-center"},[_c('label',{attrs:{"for":"extraction-date"}},[_vm._v(_vm._s(_vm.$t("invoices.table.created")))]),_c('v-menu',{ref:"dateMenu",attrs:{"id":"extraction-date","close-on-content-click":false,"transition":"scale-transition","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-5",attrs:{"prepend-icon":"mdi-calendar","readonly":"","clearable":"","hide-details":""},on:{"click:clear":_vm.clearDate},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"color":"#1772BD","range":"","max":(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),"min":"1950-01-01"},on:{"change":_vm.dateOnChange},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1)],1)],1),_c('v-col',{attrs:{"md":"2","cols":"12"}},[_c('v-card',{staticClass:"pa-4 ml-md-2 mr-md-2 text-center my-5 my-md-0"},[_c('label',{attrs:{"for":"contract-type"}},[_vm._v(_vm._s(_vm.$t("invoices.table.contract_type")))]),_c('v-select',{staticClass:"mt-5",attrs:{"id":"contract-type","color":"#1772BD","items":_vm.itemsPropContractTypes,"item-title":"text","item-value":"text","no-data-text":"","hide-details":""},on:{"focus":function ($event) {
              $event.target.click();
            },"change":_vm.contractTypeOnChange},model:{value:(_vm.contractType),callback:function ($$v) {_vm.contractType=$$v},expression:"contractType"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }