<template>
  <v-col class="pa-0">
    <v-card>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="companies"
        sort-by="id"
        mobile-breakpoint="900"
        class="elevation-0"
        :items-per-page="20"
        :footer-props="{'items-per-page-options':[20, 50, 100, -1]}"
        item-key="id"
        :search="search"
        :loading="loading"
      >
        <template #top>
          <v-toolbar
            flat
          >
            {{ $t('companies.list') }}
            <v-divider
              class="mx-4"
              inset
              vertical
            />

            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('actions.search')"
              single-line
              hide-details
            />
            <v-dialog
              v-if="dialog"
              v-model="dialog"
              max-width="700px"
            >
              <v-card class="pa-4">
                <v-card-title class="pb-6">
                  <span class="text-h4">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container class="pa-0">
                    <v-form
                      ref="form"
                      lazy-validation
                    >
                      <v-row>
                        <template
                          v-for="(v, k) in editedItem"
                        >
                          <v-col
                            v-if="(k !== 'id')"
                            :key="k"
                            cols="12"
                            sm="6"
                            md="6"
                          >
                            <v-select
                              v-if="editedItem[k].type === 'select'"
                              v-model="editedItem[k].value"
                              v-mask="editedItem[k].mask"
                              :disabled="editedItem[k].disabled"
                              :items="checklists[editedItem[k].items]"
                              item-title="text"
                              item-value="text"
                              return-object
                              :label="translate(k)"
                              :rules="checkRules(k)"
                              :prefix="editedItem[k].prefix"
                              no-data-text=""
                              @focus="
                                ($event) => {
                                  $event.target.click();
                                }
                              "
                            />
                            <v-combobox
                              v-else-if="editedItem[k].type === 'select-insert'"
                              v-model="editedItem[k].value"
                              v-mask="editedItem[k].mask"
                              :disabled="editedItem[k].disabled"
                              :items="checklists[editedItem[k].items]"
                              item-title="text"
                              item-value="text"
                              :label="translate(k)"
                              :rules="checkRules(k)"
                              :prefix="editedItem[k].prefix"
                              no-data-text=""
                              @focus="
                                ($event) => {
                                  $event.target.click();
                                }
                              "
                            />
                            <v-text-field
                              v-else
                              v-model.trim="editedItem[k].value"
                              v-mask="editedItem[k].mask"
                              :disabled="editedItem[k].disabled"
                              :label="translate(k)"
                              :type="editedItem[k].type"
                              :rules="checkRules(k)"
                              :prefix="editedItem[k].prefix"
                            />
                          </v-col>
                        </template>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="red darken-1"
                    text
                    @click="close"
                  >
                    {{ $t('actions.close') }}
                  </v-btn>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="save"
                  >
                    {{ $t('actions.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="dialogDelete"
              max-width="500px"
            >
              <v-card>
                <v-card-title class="text-h5">
                  {{ $t('actions.delete') }}
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="red darken-1"
                    text
                    @click="closeDelete"
                  >
                    {{ $t('actions.cancel') }}
                  </v-btn>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="deleteItemConfirm"
                  >
                    {{ $t('actions.ok') }}
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template #item.actions="{ item }">
          <div class="d-flex">
            <v-hover v-slot="{ hover }">
              <v-card
                class="icon-card"
                :elevation="hover ? 3 : 0"
              >
                <v-icon

                  small
                  class="mr-2"
                  @click.stop.prevent="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
              </v-card>
            </v-hover>
            <v-hover v-slot="{ hover }">
              <v-card
                class="icon-card"
                :elevation="hover ? 3 : 0"
              >
                <v-icon
                  class="pa-2"
                  small
                  @click.stop.prevent="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </v-card>
            </v-hover>
          </div>
        </template>
        <template #no-data>
          <v-btn
            color="primary"
            @click="getCompanies"
          >
            {{ $t("actions.reset") }}
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-col>
</template>

<script>
import api from "@/resources/axios.json";
import _ from "lodash";
import companyData from "@/resources/company.json";
import services from "@/services/ServiceMethods";

export default {
  name: "CompanyList",
  data() {
    return {
      api: api,
      loading: true,
      dialog: false,
      dialogDelete: false,
      search: '',
      selected: [],
      headers: [
        {
          text: this.$t('companies.table.id'),
          align: 'start',
          value: 'id'
        },
        {text: this.$t('companies.table.recipientCompanyName'), value: 'recipientCompanyName.value'},
        {text: this.$t('companies.table.recipientCompanyOin'), value: 'recipientCompanyOin.value'},
        {text: this.$t('companies.table.contractorCompanyName'), value: 'contractorCompanyName.value'},
        {text: this.$t('companies.table.contractorCompanyOin'), value: 'contractorCompanyOin.value'},
        {text: this.$t('companies.table.text'), value: 'text.value'},
        {text: this.$t('companies.table.bef_cont'), value: 'bef_cont.value'},
        {text: this.$t('companies.table.vat_dp'), value: 'vat_dp.value'},
        {text: this.$t('companies.table.vat_kv'), value: 'vat_kv.value'},
        {text: this.$t('companies.table.account'), value: 'account.value'},
        {text: this.$t('actions.actions'), value: 'actions', sortable: false},
      ],
      companies: [],
      checklists: {},
      editedIndex: -1,
      itemBackup: {},
      editedItem: companyData.item,
      defaultItem: companyData.item,
      rules: {
        required: value => !!value || this.$t("validations.required"),
        counter(n, self) {
          return value => (value && value.length || !value) <= n || self.$tc("validations.max_characters", n);
        },
        oin: value => (value && value.length === 6) || (value && value.length === 8) || this.$t("validations.oin"),
      },
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? this.$t('actions.new.company') : this.$t('actions.edit.company');
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  async created() {
    await this.getChecklists();
    await this.getCompanies();
    this.loading = false;
  },

  methods: {
    async getChecklists(reload) {
      this.loading = true;
      this.checklists = await this.$store.getters.getChecklists(reload);
    },
    translate(v) {
      return this.$t('companies.table.' + v);
    },
    async getCompanies() {
      const companiesResponse = await services.get(api.get.companies);
      this.setCompanies(companiesResponse ? companiesResponse.data : null);
    },
    setCompanies(data) {
      if(!data) {
        return null;
      }

      for (let item of data) {
        let company = _.cloneDeep(companyData.item);

        company.id = item.id;
        company.recipientCompanyName.id = item.recipient ? item.recipient.company.id : "";
        company.recipientCompanyName.value = item.recipient ? item.recipient.company.name : "";
        company.recipientCompanyOin.id = item.recipient ? item.recipient.company.id : "";
        company.recipientCompanyOin.value = item.recipient ? item.recipient.company.oin : "";
        company.contractorCompanyName.id = item.contractor ? item.contractor.company.id : "";
        company.contractorCompanyName.value = item.contractor ? item.contractor.company.name : "";
        company.contractorCompanyOin.id = item.contractor ? item.contractor.company.id : "";
        company.contractorCompanyOin.value = item.contractor ? item.contractor.company.oin : "";
        company.text.value = item.text ? item.text.text : "";

        if (item.bef_cont === null) {
          company.bef_cont.value = "";
        } else {
          company.bef_cont.value = item.bef_cont.value;
        }

        if (item.vat_kv === null) {
          company.vat_kv.value = "";
        } else {
          company.vat_kv.value = item.vat_kv.vat_kv;
        }

        if (item.vat_dp === null) {
          company.vat_dp.value = "";
        } else {
          company.vat_dp.value = item.vat_dp.vat_dp;
        }

        if (item.account === null) {
          company.account.value = "";
        } else {
          company.account.value = item.account.account;
        }

        this.companies.push(company);
      }
    },
    newCompany() {
      this.editedItem = _.cloneDeep(companyData.item);
      this.dialog = true;
    },
    editItem(item) {
      this.editedIndex = this.companies.indexOf(item);
      this.editedItem = _.cloneDeep(item);
      this.defaultItem = _.cloneDeep(this.editedItem);
      this.dialog = true;
    },

    deleteItem(item) {
      this.dialog = false;
      this.editedIndex = this.companies.indexOf(item);
      this.editedItem = _.cloneDeep(item);
      this.defaultItem = _.cloneDeep(this.editedItem);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.companies.splice(this.editedIndex, 1);
      await services.delete(api.delete.companies + this.editedItem.id, this.editedItem);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
      this.editedItem = _.cloneDeep(this.defaultItem);
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
      this.editedItem = _.cloneDeep(this.defaultItem);
    },

    async save() {
      await new Promise(r => setTimeout(r, 100));

      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          /**
           * Edit
           */

          const updateResponse = await services.put(api.put.companies + this.editedItem.id, this.editedItem, true);
          if(!updateResponse) {
            return null;
          }
          if (updateResponse.status === 201) {
            await this.getChecklists(true);
            this.loading = false;
          }

          if (updateResponse.status === 200 || updateResponse.status === 201) {
            this.companies = [];
            this.setCompanies(updateResponse.data ? updateResponse.data.data : null);
          }

        } else {
          /**
           * New
           */
          const response = await services.post(api.post.companies, this.editedItem);
          const postCompanies = response ? response.data : null;
          const postCompaniesStatus = response ? response.status : null;

          if(postCompaniesStatus === 200 || postCompaniesStatus === 201) {
            this.companies = [];
            this.setCompanies(postCompanies);
          }
        }
      }
      this.close();
    },

    checkRules(k) {
      let rules = [];
      for (let i = 0; i < Object.keys(companyData.item).length; i++) {
        if (k === Object.keys(companyData.item)[i]) {
          let rv = (Object.values(companyData.item)[i].rules);
          for (let j = 0; j < rv.length; j++) {
            let rvj = Object.values(companyData.item)[i].rules[j];
            if (rvj.toString() === 'required') {
              rules.push(this.rules.required);
            } else if (rvj.toString().startsWith('counter')) {
              let chars = rvj.split('-');
              rules.push(this.rules.counter(parseInt(chars[1]), this));
            } else if (rvj.toString() === 'oin') {
              rules.push(this.rules.oin);
            } else {
              console.error(`Rule "${rvj}" is not defined! Add an exception to the method "checkRules".`);
            }
          }
        }
      }

      return rules;
    }
  },
};
</script>

<style lang="css" scoped>
button.v-icon {
  margin: 0 !important;
}

.icon-card {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 1.25rem;
  height: 1.25rem;
}

.v-data-table >>> th {
  /*white-space: nowrap;*/
  vertical-align: top;
}
</style>
