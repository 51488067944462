<template>
  <v-row v-show="!loading">
    <v-col cols="12">
      <invoice-panel-section
        v-if="!reload"
        ref="invoice-panel-section-generals"
        :section="filterId(invoicesProp.generals)"
        :select="['contract_type', 'document_type']"
        :select-items="contractTypesItems"
        :select-documents="documentTypesItems"
        :name="$t('invoices.extraction.general')"
        original-name="generals"
        :invoice-data="invoiceData.generals"
        @changeObject="setSection"
        @file-moved="$emit('file-moved', $event)"
        @change:dateDelivery="$emit('change:dateDelivery')"
        @loaded="setLoading"
      />
    </v-col>
    <v-col cols="12">
      <invoice-panel-section
        v-if="!reload"
        ref="invoice-panel-section-contractor"
        :section="filterId(invoicesProp.contractor)"
        :name="$t('invoices.extraction.contractor')"
        :invoice-data="invoiceData.contractor"
        original-name="contractor"
        @changeObject="setSection"
        @change:oin="$emit('change:oin')"
        @loaded="setLoading"
      />
    </v-col>
    <v-col cols="12">
      <invoice-panel-section
        v-if="!reload"
        ref="invoice-panel-section-recipient"
        :section="filterId(invoicesProp.recipient)"
        :name="$t('invoices.extraction.recipient')"
        :invoice-data="invoiceData.recipient"
        original-name="recipient"
        @changeObject="setSection"
        @change:oin="$emit('change:oin')"
        @loaded="setLoading"
      />
    </v-col>
    <v-col cols="12">
      <invoice-panel-section
        v-if="!reload"
        ref="invoice-panel-section-additional"
        :section="filterId(invoicesProp.additional)"
        :name="$t('invoices.extraction.additional')"
        :invoice-data="invoiceData.additional"
        :checklists="checklists"
        original-name="additional"
        @changeObject="setSection"
        @loaded="setLoading"
      />
    </v-col>
  </v-row>
</template>

<script>
import InvoicePanelSection from "@/components/invoice/InvoicePanelSection";
//import InvoiceTableSection from "@/components/invoice/InvoiceTableSection";
import invoiceData from '@/resources/invoice.json';

export default {
  name: "InvoiceExtractionList",
  components: {
    //InvoiceTableSection,
    InvoicePanelSection,
  },
  props: {
    invoices: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      invoicesProp: this.invoices,
      invoiceData: invoiceData,
      checklists: this.$store.state.checklists,
      loading: true,
      reload: true,
      loadingSections: {
        generals: true,
        contractor: true,
        recipient: true,
        additional: true
      },
      contractTypesItems: [
        {id: this.$t('invoices.type.vfa.name'), text: this.$t('invoices.type.vfa.lang')},
        {id: this.$t('invoices.type.pfa.name'), text: this.$t('invoices.type.pfa.lang')},
        {id: this.$t('invoices.type.not_sorted.name'), text: this.$t("invoices.type.not_sorted.lang")}
      ],
      documentTypesItems: [
        {id: this.$t('invoices.type.document.invoice.name'), text: this.$t('invoices.type.document.invoice.lang')},
        {id: this.$t('invoices.type.document.debit_note.name'), text: this.$t('invoices.type.document.debit_note.lang')},
        {id: this.$t('invoices.type.document.credit_note.name'), text: this.$t('invoices.type.document.credit_note.lang')}
      ]
    };
  },
  watch: {
    loadingSections: {
      handler() {
        let loaded = false;
        for(let i in this.loadingSections) {
          if(this.loadingSections[i]) {
            this.loading = true;
            loaded = false;
            break;
          } else {
            loaded = true;
          }
        }
        if(loaded) {
          this.loading = false;
          this.$emit("loaded");
        }
      },
      deep: true
    },
    invoices: {
      handler() {
        this.invoicesProp = this.invoices;
      },
      deep: true
    },
    'invoices.internal_number': {
      handler() {
        if(this.invoicesProp.additional) {
          this.invoicesProp.additional.internal_number.value = this.invoices.internal_number;
        }
      },
      deep: true
    },
  },
  async created() {
    this.loading = true;
    this.reload = true;
    if (!this.checklists || Object.keys(this.checklists).length === 0) {
      this.checklists = await this.$store.getters.getChecklists;
      await this.$store.dispatch("setChecklists", this.checklists);
    }
    this.reload = false;
  },
  methods: {
    setLoading(section) {
      this.loadingSections[section] = false;
    },
    filterId(value) {
      if (value) {
        delete value.id;
      }
      return value;
    },
    setSection(response) {
      this.reload = true;
      for (let item in this.invoicesProp[response.item]) {
        if (this.invoicesProp[response.item][item].id === response.data.id) {
          this.invoicesProp[response.item][item] = response.data;
        }
      }
      this.reload = false;
    }
  }
};

</script>

<style scoped>

</style>
