<template>
  <v-col class="pa-0">
    <v-card>
      <v-data-table
        ref="tableClients"
        v-model="selected"
        :headers="headers"
        :items="clients"
        mobile-breakpoint="900"
        sort-by="organization"
        class="elevation-0 row-pointer"
        :items-per-page="20"
        :footer-props="{'items-per-page-options':[20, 50, 100, -1]}"
        item-key="id"
        :search="search"
        :loading="loading"
        @click:row="showClientInvoices"
      >
        <template #top>
          <v-toolbar
            flat
          >
            {{ $t('clients.list') }}
            <v-divider
              class="mx-4"
              inset
              vertical
            />

            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('actions.search')"
              single-line
              hide-details
            />
            <div class="d-flex justify-content-end">
              <v-btn
                color="primary"
                dark
                class="ma-2"
                @click="newClient"
              >
                {{ $t('actions.new.client') }}
              </v-btn>
            </div>
            <v-dialog
              v-if="dialog"
              v-model="dialog"
              max-width="700px"
            >
              <v-card class="pa-4">
                <v-card-title class="pb-6">
                  <span class="text-h4">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container class="pa-0">
                    <v-form
                      ref="form"
                      lazy-validation
                    >
                      <v-row>
                        <template
                          v-for="(v, k) in editedItem"
                        >
                          <v-col
                            v-if="(k !== 'id') && (k !== 'name_surname')"
                            :key="k"
                            cols="12"
                            sm="6"
                            md="6"
                          >
                            <v-select
                              v-if="editedItem[k].type === 'select'"
                              v-model="editedItem[k].value"
                              v-mask="editedItem[k].mask"
                              :disabled="editedItem[k].disabled"
                              :items="checklists[editedItem[k].items]"
                              item-title="text"
                              item-value="text"
                              return-object
                              :label="translate(k)"
                              :rules="checkRules(k)"
                              :prefix="editedItem[k].prefix"
                              no-data-text=""
                              @focus="
                                ($event) => {
                                  $event.target.click();
                                }
                              "
                            />
                            <v-combobox
                              v-else-if="editedItem[k].type === 'select-insert'"
                              v-model="editedItem[k].value"
                              v-mask="editedItem[k].mask"
                              :disabled="editedItem[k].disabled"
                              :items="checklists[editedItem[k].items]"
                              item-title="text"
                              item-value="text"
                              :label="translate(k)"
                              :rules="checkRules(k)"
                              :prefix="editedItem[k].prefix"
                              no-data-text=""
                              @focus="
                                ($event) => {
                                  $event.target.click();
                                }
                              "
                            />
                            <v-text-field
                              v-else
                              v-model="editedItem[k].value"
                              v-mask="editedItem[k].mask"
                              :disabled="editedItem[k].disabled"
                              :label="translate(k)"
                              :type="editedItem[k].type"
                              :rules="checkRules(k)"
                              :prefix="editedItem[k].prefix"
                            />
                          </v-col>
                        </template>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="red darken-1"
                    text
                    @click="close"
                  >
                    {{ $t('actions.close') }}
                  </v-btn>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="save"
                  >
                    {{ $t('actions.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="dialogDelete"
              max-width="500px"
            >
              <v-card>
                <v-card-title class="text-h5">
                  {{ $t('actions.delete') }}
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="red darken-1"
                    text
                    @click="closeDelete"
                  >
                    {{ $t('actions.cancel') }}
                  </v-btn>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="deleteItemConfirm"
                  >
                    {{ $t('actions.ok') }}
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template #item.phone.value="{ item }">
          <textarea
            v-model="item.phone.value"
            v-mask="item.phone.mask"
            class="transparent-input"
            disabled
          />
        </template>
        <template #item.client_status="{ item }">
          <v-chip
            :color="getStatusColor(item.client_status)"
            dark
          >
            {{ translateStatus(item.client_status) }}
          </v-chip>
        </template>
        <template #item.actions="{ item }">
          <div class="d-flex">
            <v-hover v-slot="{ hover }">
              <v-card
                class="icon-card"
                :elevation="hover ? 3 : 0"
              >
                <v-icon

                  small
                  class="mr-2"
                  @click.stop.prevent="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
              </v-card>
            </v-hover>
            <v-hover v-slot="{ hover }">
              <v-card
                class="icon-card"
                :elevation="hover ? 3 : 0"
              >
                <v-icon
                  small
                  class="pa-2"
                  @click.stop.prevent="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </v-card>
            </v-hover>
          </div>
        </template>
        <template #no-data>
          <v-btn
            color="primary"
            @click="getClients"
          >
            {{ $t("actions.reset") }}
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-col>
</template>

<script>

import api from "@/resources/axios.json";
import _ from 'lodash';
import services from "@/services/ServiceMethods";
import clientData from "@/resources/client.json";
import Vue from "vue";
import StatusUtil from "@/utils/StatusUtil";

export default {
  name: "ClientList",
  data() {
    return {
      api: api,
      loading: true,
      dialog: false,
      dialogDelete: false,
      search: '',
      selected: [],
      headers: [
        {
          text: this.$t('clients.table.id'),
          align: 'start',
          value: 'id',
        },
        {text: this.$t('clients.table.organization'), value: 'organization.value'},
        {text: this.$t('clients.table.oin'), value: 'oin.value'},
        {text: this.$t('clients.table.vat'), value: 'vat.value'},
        {text: this.$t('clients.table.address_street'), value: 'address_street.value'},
        {text: this.$t('clients.table.address_city'), value: 'address_city.value'},
        {text: this.$t('clients.table.name_surname'), value: 'name_surname.value'},
        {text: this.$t('clients.table.phone'), value: 'phone.value'},
        {text: this.$t('clients.table.internal_number'), value: 'internal_number.value'},
        {text: this.$t('clients.table.acc_system'), value: 'acc_system.value'},
        {text: this.$t('clients.table.folder_path'), value: 'folder_path.value'},
        {text: this.$t('clients.table.administrator'), value: 'user_name_surname'},
        {text: this.$t('clients.table.status'), value: 'client_status'},
        {text: this.$t('actions.actions'), value: 'actions', sortable: false},
      ],
      clients: [],
      checklists: {},
      editedIndex: -1,

      editedItem: clientData.item,
      defaultItem: clientData.item,
      rules: {
        required: value => !!value || this.$t("validations.required"),
        counter(n, self) {
          return value => (value && value.length || !value) <= n || self.$tc("validations.max_characters", n);
        },
        vat: value => (value && value.length === 10) || this.$t("validations.vat"),
        oi_vat: value => (!value || (value && value.length) === 12) || this.$t("validations.oi_vat"),
        oin: value => (value && value.length === 6) || (value && value.length === 8) || this.$t("validations.oin"),
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return (!value || pattern.test(value)) || this.$t("validations.email");
        },
      },
      prefix: {
        phone: "+"
      }
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? this.$t('actions.new.client') : this.$t('actions.edit.client');
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  async created() {
    await this.getChecklists();
    await this.getClients();
    this.loading = false;
  },

  methods: {
    async getChecklists(reload) {
      this.loading = true;
      this.checklists.accSystems = await this.$store.getters.getChecklist("accSystems", reload);
      this.checklists.positions = await this.$store.getters.getChecklist("positions", reload);
    },
    async newClient() {
      this.editedItem = _.cloneDeep(clientData.item);

      const latestInternalNumberResponse = await services.get(api.get.latestInternalNumber);
      await this.$store.dispatch("setInvoiceInformation", {key: "latestInternalNumber", value: latestInternalNumberResponse.data.internal_number});
      this.editedItem.internal_number.value = this.numberIterator(this.$store.state.invoiceInformation.latestInternalNumber);
      this.dialog = true;
    },
    translate(v) {
      return this.$t('clients.table.' + v);
    },
    numberIterator(value) {
      if (value === null) {
        return value;
      }
      let array = value.split("PF");

      if (array.length === 2) {
        const lengthBefore = array[1].length;
        array[1] = parseInt(array[1]);
        array[1] = array[1] + 1;

        const lengthAfter = array[1].toString().length;

        if (lengthAfter < lengthBefore) {
          let missingLength = lengthBefore - lengthAfter;

          let finalValue = 'PF';
          for (let i = 0; i < missingLength; i++) {
            finalValue += '0';
          }
          finalValue += array[1];
          return finalValue;
        } else {
          return "PF" + array[1].toString();
        }
      } else {
        return value;
      }
    },
    async getClients() {
      const clientsResponse = await services.get(api.get.allClientsLight);
      this.setClients(clientsResponse ? clientsResponse.data : null);
    },
    setClients(response) {
      if(!response) {
        return null;
      }

      for (let item of response) {
        let client =  _.cloneDeep(clientData.item);

        client.id = item.id;
        client.organization.value = item.organization;
        client.name_surname.value = item.name + ' ' + item.surname;
        client.address_street.value = item.address_street;
        client.address_city.value = item.address_city;
        client.address_postcode.value = item.address_postcode;
        client.address_state.value = item.address_state;
        client.oin.value = item.oin;
        client.vat.value = item.vat;
        client.oi_vat.value = item.oi_vat;
        client.name.value = item.name;
        client.surname.value = item.surname;
        client.position.value = item.position ? item.position.position : "";
        client.email.value = item.email;
        client.phone.value = item.phone;
        // TODO nefunguje getovanie usera z DB, pravdepodobne kvoli nonnumeric id, leftJoin ho sice getne ale pobiju sa stlpce
        client.user_name_surname = item.user ? item.user.name : (item.user_name ? item.user_name : "");
        client.client_status = StatusUtil.parseClientStatus(item.client_status);
        client.folder_path.value = item.folder_path;
        client.internal_number.value = item.internal_number;
        client.acc_system.value = item.acc_system ? item.acc_system.account_system : "";

        this.clients.push(client);
      }
    },
    showClientInvoices(item) {
      this.$router.push({
        name: "InvoicesClient",
        params: { oin:  item.oin.value},
      });
    },
    editItem(item) {
      this.editedIndex = this.clients.indexOf(item);
      this.editedItem = _.cloneDeep(item);
      this.defaultItem = _.cloneDeep(this.editedItem);

      delete this.editedItem.user_name_surname;
      delete this.editedItem.client_status;

      this.dialog = true;
    },

    deleteItem(item) {
      this.dialog = false;
      this.editedIndex = this.clients.indexOf(item);
      this.editedItem = _.cloneDeep(item);
      this.defaultItem = _.cloneDeep(this.editedItem);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.clients.splice(this.editedIndex, 1);
      await services.delete( api.delete.clients + this.editedItem.id, this.editedItem);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
      this.editedItem = _.cloneDeep(this.defaultItem);
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
      this.editedItem = _.cloneDeep(this.defaultItem);
    },

    transformItem(item) {
      let transformedItem = {};
      Object.keys(item).forEach((key) => {
        if(item[key].value || item[key].value === "") {
          if(key === "address_postcode" || key === "phone") {
            transformedItem[key] = item[key].value.replace(/\s|\+/g, "");
          } else if(item[key].type.includes("select")) {
            transformedItem[key] = item[key].value;
          } else {
            transformedItem[key] = item[key].value.trim();
          }
        } else if(item[key].value === null) {
            transformedItem[key] = null;
        } else {
          transformedItem[key] = item[key];
        }
      });

      return transformedItem;
    },

    checkRules(k) {
      let rules = [];
      for (let i = 0; i < Object.keys(clientData.item).length; i++) {
        if (k === Object.keys(clientData.item)[i]) {
          let rv = (Object.values(clientData.item)[i].rules);
          for (let j = 0; j < rv.length; j++) {
            let rvj = Object.values(clientData.item)[i].rules[j];
            if (rvj.toString() === 'required') {
              rules.push(this.rules.required);
            } else if (rvj.toString().startsWith('counter')) {
              let chars = rvj.split('-');
              rules.push(this.rules.counter(parseInt(chars[1]), this));
            } else if (rvj.toString() === 'oin') {
              rules.push(this.rules.oin);
            } else if (rvj.toString() === 'vat') {
              rules.push(this.rules.vat);
            } else if (rvj.toString() === 'oi_vat') {
              rules.push(this.rules.oi_vat);
            } else if (rvj.toString() === 'email') {
              rules.push(this.rules.email);
            } else {
              console.error(`Rule "${rvj}" is not defined! Add an exception to the method "checkRules".`);
            }
          }
        }
      }

      return rules;
    },

    async save() {
      await new Promise(r => setTimeout(r, 100));

      if (this.editedIndex > -1) {
        /**
         * Edit
         */
        if (this.$refs.form.validate()) {
          const updateResponse = await services.put( api.put.clients + this.editedItem.id, this.transformItem(this.editedItem), true);
          if(!updateResponse) {
            return null;
          }
          if(updateResponse.status === 201) {
            await this.getChecklists(true);
            this.loading = false;
          }

          if(updateResponse.status === 200 || updateResponse.status === 201) {
            this.clients = [];
            this.setClients(updateResponse.data ? updateResponse.data.data : null);
          }
          this.close();
        } else {
          Vue.$toast(this.$t('actions.errorHandle.errorForm'), {
            toastClassName: "danger-class",
          });
        }
      } else {
        /**
         * New
         */
        if (this.$refs.form.validate()) {
         const response = await services.post(api.post.clients, this.transformItem(this.editedItem));
         const postClients = response ? response.data.data : null;
         const postClientsStatus = response ? response.status : null;

          if(postClientsStatus === 200 || postClientsStatus === 201) {
            this.clients = [];
            this.setClients(postClients);
          }
          this.close();
        } else {
          Vue.$toast(this.$t('actions.errorHandle.errorForm'), {
            toastClassName: "danger-class",
          });
        }
      }
    },
    getStatusColor(status) {
      if (status === 'NEW') return 'bg-color bg-blue';
      else if (status === 'ACCEPTED') return 'bg-color bg-yellow';
      else return 'bg-color bg-dk-blue';
    },
    translateStatus(v) {
      return this.$t('clients.statuses.' + v);
    }
  },
};
</script>

<style lang="scss" scoped>
.row-pointer ::v-deep tbody tr :hover {
  cursor: pointer;
}
button.v-icon {
  margin: 0 !important;
}
.icon-card {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 1.25rem;
  height: 1.25rem;
}
.transparent-input {
  resize: none;
  vertical-align: middle;
  overflow: hidden;
  max-height: 1.25rem;
  max-width: 7.75rem;
  color: inherit !important;
}
</style>