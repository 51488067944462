var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"pa-0"},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.companies,"sort-by":"id","mobile-breakpoint":"900","items-per-page":20,"footer-props":{'items-per-page-options':[20, 50, 100, -1]},"item-key":"id","search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_vm._v(" "+_vm._s(_vm.$t('companies.list'))+" "),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('actions.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.dialog)?_c('v-dialog',{attrs:{"max-width":"700px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-card-title',{staticClass:"pb-6"},[_c('span',{staticClass:"text-h4"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',{staticClass:"pa-0"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-row',[_vm._l((_vm.editedItem),function(v,k){return [((k !== 'id'))?_c('v-col',{key:k,attrs:{"cols":"12","sm":"6","md":"6"}},[(_vm.editedItem[k].type === 'select')?_c('v-select',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.editedItem[k].mask),expression:"editedItem[k].mask"}],attrs:{"disabled":_vm.editedItem[k].disabled,"items":_vm.checklists[_vm.editedItem[k].items],"item-title":"text","item-value":"text","return-object":"","label":_vm.translate(k),"rules":_vm.checkRules(k),"prefix":_vm.editedItem[k].prefix,"no-data-text":""},on:{"focus":function ($event) {
                                $event.target.click();
                              }},model:{value:(_vm.editedItem[k].value),callback:function ($$v) {_vm.$set(_vm.editedItem[k], "value", $$v)},expression:"editedItem[k].value"}}):(_vm.editedItem[k].type === 'select-insert')?_c('v-combobox',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.editedItem[k].mask),expression:"editedItem[k].mask"}],attrs:{"disabled":_vm.editedItem[k].disabled,"items":_vm.checklists[_vm.editedItem[k].items],"item-title":"text","item-value":"text","label":_vm.translate(k),"rules":_vm.checkRules(k),"prefix":_vm.editedItem[k].prefix,"no-data-text":""},on:{"focus":function ($event) {
                                $event.target.click();
                              }},model:{value:(_vm.editedItem[k].value),callback:function ($$v) {_vm.$set(_vm.editedItem[k], "value", $$v)},expression:"editedItem[k].value"}}):_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.editedItem[k].mask),expression:"editedItem[k].mask"}],attrs:{"disabled":_vm.editedItem[k].disabled,"label":_vm.translate(k),"type":_vm.editedItem[k].type,"rules":_vm.checkRules(k),"prefix":_vm.editedItem[k].prefix},model:{value:(_vm.editedItem[k].value),callback:function ($$v) {_vm.$set(_vm.editedItem[k], "value", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"editedItem[k].value"}})],1):_vm._e()]})],2)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('actions.close'))+" ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('actions.save'))+" ")])],1)],1)],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t('actions.delete'))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v(" "+_vm._s(_vm.$t('actions.cancel'))+" ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v(" "+_vm._s(_vm.$t('actions.ok'))+" ")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                              var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var hover = ref.hover;
return [_c('v-card',{staticClass:"icon-card",attrs:{"elevation":hover ? 3 : 0}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var hover = ref.hover;
return [_c('v-card',{staticClass:"icon-card",attrs:{"elevation":hover ? 3 : 0}},[_c('v-icon',{staticClass:"pa-2",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)})],1)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.getCompanies}},[_vm._v(" "+_vm._s(_vm.$t("actions.reset"))+" ")])]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }