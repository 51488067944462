<template>
  <div>
    <v-row no-gutters>
      <v-col
        md="4"
        cols="12"
      >
        <v-card class="pa-4 mr-md-2 text-center">
          <label
            for="min-confidence"
          >{{ $t("invoices.table.min_confidence") }}</label>
          <v-range-slider
            id="min-confidence"
            v-model="rangeSlider"
            :max="maxSlider"
            :min="minSlider"
            hide-details
            class="align-center mt-5"
            color="#1772BD"
            @change="rangeSliderOnChange"
          >
            <template #prepend>
              <v-text-field
                :value="rangeSlider[0]"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
                @change="$set(rangeSlider, 0, $event); rangeSliderOnChange();"
              />
            </template>
            <template #append>
              <v-text-field
                :value="rangeSlider[1]"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
                @change="$set(rangeSlider, 1, $event); rangeSliderOnChange();"
              />
            </template>
          </v-range-slider>
        </v-card>
      </v-col>

      <v-col
        md="3"
        cols="12"
      >
        <v-card class="pa-4 ml-md-2 mr-md-2 text-center my-5 my-md-0">
          <label for="acc-system">{{ $t("invoices.table.acc_system") }}</label>
          <v-select
            id="acc-system"
            v-model="selectContent"
            class="mt-5"
            color="#1772BD"
            :items="itemsProp"
            item-title="text"
            item-value="text"
            no-data-text=""
            hide-details
            @focus="
              ($event) => {
                $event.target.click();
              }
            "
            @change="selectOnChange"
          />
        </v-card>
      </v-col>

      <v-col
        md="3"
        cols="12"
      >
        <v-card class="pa-4 ml-md-2 text-center">
          <label for="extraction-date">{{ $t("invoices.table.created") }}</label>
          <v-menu
            id="extraction-date"
            ref="dateMenu"
            v-model="dateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="dateRange"
                class="mt-5"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                hide-details
                v-bind="attrs"
                v-on="on"
                @click:clear="clearDate"
              />
            </template>
            <v-date-picker
              v-model="dates"
              color="#1772BD"
              range
              :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
              min="1950-01-01"
              @change="dateOnChange"
            />
          </v-menu>
        </v-card>
      </v-col>

      <v-col
          md="2"
          cols="12"
      >
        <v-card class="pa-4 ml-md-2 mr-md-2 text-center my-5 my-md-0">
          <label for="contract-type">{{ $t("invoices.table.contract_type") }}</label>
          <v-select
              id="contract-type"
              v-model="contractType"
              class="mt-5"
              color="#1772BD"
              :items="itemsPropContractTypes"
              item-title="text"
              item-value="text"
              no-data-text=""
              hide-details
              @focus="
              ($event) => {
                $event.target.click();
              }
            "
              @change="contractTypeOnChange"
          />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: "TableFilters",
  props: {
    items: {
      type: Array,
      default: () => []
    },
    contractTypeItems: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      itemsProp: this.items,
      itemsPropContractTypes: this.contractTypeItems,
      minSlider: this.$store.state.filters.confidence.min,
      maxSlider: this.$store.state.filters.confidence.max,
      rangeSlider: this.$store.state.filters.confidence.range,
      selectContent: this.$store.state.filters.accSystem,
      contractType: this.$store.state.filters.contractType,

      dateMenu: false,
      dates: this.$store.state.filters.extractionDate,
    };
  },
  computed: {
    dateRange () {
      let dateRange = [];
      let dates = this.dates;
      if((new Date(dates[0])).getTime() > (new Date(dates[1])).getTime()) {
        dates = dates.reverse();
      }
      dates.forEach((element, index) => {
        if(element) {
          element = element.split("T")[0];
          dateRange[index] = element.split("-").reverse().join(". ");
        }
      });
      return dateRange.join(' ~ ');
    },
  },
  created() {
    this.itemsProp = [{id: 0, text: this.$t("actions.all")}, ...this.itemsProp];
  },
  methods: {
    clearDate() {
      this.dates = [];
      this.$emit("change", this.rangeSlider, this.selectContent, this.dates, this.contractType);
      this.$store.dispatch("setFilter", {key: "extractionDate", value: this.dates});
    },
    rangeSliderOnChange() {
      let dates = this.formatDate(this.dates);
      this.$emit("change", this.rangeSlider, this.selectContent , dates, this.contractType);
      this.$store.dispatch("setFilter", {key: "confidence", value: this.rangeSlider});
    },

    contractTypeOnChange() {
      let dates = this.formatDate(this.dates);
      this.$emit("change", this.rangeSlider, this.selectContent, dates, this.contractType);
      this.$store.dispatch("setFilter", {key: "contractType", value: this.contractType});
    },

    selectOnChange() {
      let dates = this.formatDate(this.dates);
      this.$emit("change", this.rangeSlider, this.selectContent, dates, this.contractType);
      this.$store.dispatch("setFilter", {key: "accSystem", value: this.selectContent});
    },

    dateOnChange(value) {
      let dates = this.formatDate(value);
      this.$emit("change", this.rangeSlider, this.selectContent, dates);
      this.$store.dispatch("setFilter", {key: "extractionDate", value: dates});
    },

    formatDate(value) {
      let dates = [];
      if(value && value.length > 0) {
        dates[0] = value[0] + "T23:59:59";
        dates[1] = value[1] + "T23:59:59";
        if((new Date(dates[0])).getTime() > (new Date(dates[1])).getTime()) {
          dates = dates.reverse();
        }
      }

      return dates;
    }
  }
};
</script>

<style lang="scss" scoped>
.v-card {
  height: 100%;
  display: flex;
  flex-direction: column;

  @media all and (max-width: 959px) {
    height: initial;
  }
}
::v-deep {
  .v-input {
    margin: 0;
    padding: 0;
  }
  .v-input__slider .v-input__prepend-outer, .v-input__slider .v-input__append-outer {
    margin: {
      top: 0;
      bottom: 0;
    }
  }
  .v-input {
    display: flex;
    align-items: flex-end !important;
  }
}
</style>
