<template>
  <div>
    <header-card :header="$t('menu.clients')" />
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import HeaderCard from "@/components/HeaderCard.vue";

export default {
  name: "ClientsDatabase",
  components: {
    HeaderCard
  }
};
</script>

<style scoped>

</style>