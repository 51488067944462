import Vue from 'vue';
import Vuex from 'vuex';
import services from '@/services/ServiceMethods';

Vue.use(Vuex);
import createPersistedState from "vuex-persistedstate";
import api from "@/resources/axios.json";
import i18n from "../i18n";

export const store = new Vuex.Store({
    plugins: [
        createPersistedState({
            storage: window.sessionStorage,
            reducer: state => ({
                invoiceInformation: state.invoiceInformation,
                filteredInvoices: state.filteredInvoices,
                drawer: state.drawer,
                user: state.user,
                token: state.token,
                checklists: {
                    accSystems: state.checklists.accSystems,
                    accounts: state.checklists.accounts,
                    befConts: state.checklists.befConts,
                    positions: state.checklists.positions,
                    texts: state.checklists.texts, // delete if storage overflow
                    vatDPDivisions: state.checklists.vatDPDivisions,
                    vatKVDivisions: state.checklists.vatKVDivisions,
                },
                filters: state.filters,
                documentTree: state.documentTree,
                client: state.client,
                extractionProgress: state.extractionProgress,
            })
        }),
    ],
    state: {
        invoiceInformation: {},
        filteredInvoices: [],
        drawer: {
            mini: false,
            rotate: false
        },
        user: null,
        token: null,
        checklists: {},
        filters: {
            confidence: {
                min: 0,
                max: 100,
                range: [0, 100],
            },
            accSystem: i18n.t("actions.all"),
            contractType: i18n.t("actions.all"),
            extractionDate: [],
        },
        documentTree: {},
        client: {},
        extractionProgress: {
            currentProgress: 0,
            totalProgress: 0,
            show: false,
            progressValue: 0,
            disabled: false,
        },
    },
    getters: {
        getFilteredInvoices(state) {
            return state.filteredInvoices;
        },
        getDrawer(state) {
            return state.drawer;
        },
        getUser(state) {
            return state.user;
        },
        getToken(state) {
            return state.token;
        },
        getChecklist: (state) => async (checklist, reload) => {
            if ((state.checklists && state.checklists[checklist] && state.checklists[checklist].length > 0) && !reload) {
                return state.checklists[checklist];
            } else if (reload || (state.checklists || Object.keys(state.checklists).length === 0) || (state.checklists[checklist] || state.checklists[checklist].length === 0)) {
                await services.getChecklist(api.checklist[checklist], checklist);
                return state.checklists[checklist];
            } else {
                return [];
            }
        },
        getChecklists: (state) => async (reload) =>  {
            if (!state.checklists || !state.checklists.accounts || reload) {
                await services.getChecklist(api.checklist.accounts, 'accounts');
            }
            if (!state.checklists || !state.checklists.accSystems || reload) {
                await services.getChecklist(api.checklist.accSystems, 'accSystems');
            }
            if (!state.checklists || !state.checklists.befConts || reload) {
                await services.getChecklist(api.checklist.befConts, 'befConts');
            }
            if (!state.checklists || !state.checklists.vatDPDivisions || reload) {
                await services.getChecklist(api.checklist.vatDPDivisions, 'vatDPDivisions');
            }
            if (!state.checklists || !state.checklists.vatKVDivisions || reload) {
                await services.getChecklist(api.checklist.vatKVDivisions, 'vatKVDivisions');
            }
            if (!state.checklists || !state.checklists.texts || reload) {
                await services.getChecklist(api.checklist.texts, 'texts');
            }
            if (!state.checklists || !state.checklists.positions || reload) {
                await services.getChecklist(api.checklist.positions, 'positions');
            }
            return state.checklists;
        },
        getDocumentTree(state) {
            return state.documentTree;
        },
        getLatestClient(state) {
            return state.client;
        }
    },
    mutations: {
        CHANGE_FILTERED_INVOICES(state, newValue) {
            state.filteredInvoices = newValue;
        },
        CHANGE_DRAWER(state, newValue) {
            state.drawer.mini = newValue.mini;
            state.drawer.rotate = newValue.rotate;
        },
        CHANGE_USER(state, newValue) {
            state.user = newValue;
        },
        CHANGE_TOKEN(state, newValue) {
            state.token = newValue;
        },
        SET_CHECKLIST(state, newObject) {
            state.checklists[newObject.key] = newObject.data;
        },
        SET_CHECKLISTS(state, newObject) {
            state.checklists = newObject;
        },
        SET_FILTER(state, object) {
            if (object.key === "confidence") {
                state.filters[object.key].range = object.value;
            } else {
                state.filters[object.key] = object.value;
            }
        },
        SET_DOCUMENT_TREE(state, newObject) {
            state.documentTree = newObject;
        },
        SET_LATEST_CLIENT(state, newObject) {
            state.client = newObject;
        },
        SET_EXTRACTION_PROGRESS(state, object) {
            state.extractionProgress[object.key] = object.value;
        },
        SET_INVOICE_INFORMATION(state, object) {
            state.invoiceInformation[object.key] = object.value;
        }
    },
    actions: {
        changeFilteredInvoices({commit}, newValue) {
            commit("CHANGE_FILTERED_INVOICES", newValue);
        },
        changeDrawer({commit}, newValue) {
            commit("CHANGE_DRAWER", newValue);
        },
        changeUser({commit}, newValue) {
            commit("CHANGE_USER", newValue);
        },
        changeToken({commit}, newValue) {
            commit("CHANGE_TOKEN", newValue);
        },
        setChecklist({commit}, newObject) {
            commit("SET_CHECKLIST", newObject);
        },
        setChecklists({commit}, newObject) {
            commit("SET_CHECKLISTS", newObject);
        },
        setFilter({commit}, newObject) {
            commit("SET_FILTER", newObject);
        },
        setExtractionProgress({commit}, newObject) {
            commit("SET_EXTRACTION_PROGRESS", newObject);
        },
        setDocumentTree({commit}, newObject) {
            commit("SET_DOCUMENT_TREE", newObject);
        },
        setLatestClient({commit}, newObject) {
            commit("SET_LATEST_CLIENT", newObject);
        },
        setInvoiceInformation({commit}, object) {
            commit("SET_INVOICE_INFORMATION", object);
        },
    }
});
