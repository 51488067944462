<template>
  <v-navigation-drawer
    id="app-drawer"
    v-model="drawer"
    app
    :mini-variant.sync="mini"
    permanent
    class="bg-color bg-blue ft-color ft-white-deep"
  >
    <v-list-item
      class="pa-3 pt-8 pb-8"
      link
    >
      <v-list-item-title>
        <v-img
          max-width="100%"
          :src="logo"
        />
      </v-list-item-title>
    </v-list-item>

    <v-divider />
    <v-list
      v-if="!rotate"
      dense
    >
      <v-list-item
        link
      >
        <v-list-item-content
          class="font-bold justify-center "
          style="font-size: 1.5rem"
        >
          {{ $t('application.product.name') }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content class="justify-center">
          {{ user.name }}
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider />

    <v-list dense>
      <v-list-item
        v-for="item in items"
        :key="item.title"
        :to="item.link"
        @click="rotate = false"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="logOut">
        <v-list-item-icon>
          <v-icon> mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> {{ $t('menu.logout') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider />

    <v-btn
      class="ml-2"
      icon
      @click.stop="drawerStatus"
    >
      <v-icon :style="{transform: rotate ? 'rotate(180deg)' : 'rotate(0deg)'}">
        mdi-chevron-left
      </v-icon>
    </v-btn>
  </v-navigation-drawer>
</template>

<script>

import authentication from "@/authentication";

export default {
  name: "NavigationMenu",
  data() {
    return {
      mini: false,
      user: {},
      drawer: false,
      rotate: false,
      items: [
        {title: this.$t('menu.invoices'), icon: 'mdi-receipt', link: '/invoice-detection'},
        {title: this.$t('menu.clients'), icon: 'mdi-account-check-outline', link: '/client-list'},
        {title: this.$t('menu.companies'), icon: 'mdi-domain', link: '/company-list'},
      ],
    };
  },
  computed: {
    logo() {
      return require("@/assets/logo/logo_white.svg");
    },
    isAuthenticated() {
      return authentication.isAuthenticated();
    }
  },
  async created() {

    this.mini = this.$store.state.drawer.mini;
    this.rotate = this.$store.state.drawer.rotate;

    await this.$store.dispatch(`changeUser`, authentication.getUserProfile());
    await this.$store.dispatch(`changeToken`, localStorage.getItem('adal.idtoken'));

    if(!this.$store.state.token) {
      this.logOut();
    }

    this.user = this.$store.state.user;

  },
  methods: {
    logOut() {
      authentication.signOut();
    },
    drawerStatus() {
      this.mini = !this.mini;
      this.rotate = !this.rotate;
      this.$store.dispatch(`changeDrawer`, {mini: this.mini, rotate: this.rotate});
    }
  }
};
</script>

<style scoped lang="scss">
@import "src/scss/_global.scss";

</style>