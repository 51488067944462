<template>
  <div :class="{'loading': loading || !loadedInvoices}">
    <v-progress-circular
      v-if="loading || !loadedInvoices"
      size="80"
      width="8"
      indeterminate
      color="primary"
    />
    <v-row v-show="!loading && loadedInvoices">
      <v-col
        cols="12"
        md="6"
      >
        <v-dialog
          v-model="dialogModal.open"
          max-width="400"
        >
          <v-card>
            <v-card-title class="text-h5">
              {{ dialogModal.headline }}
            </v-card-title>

            <v-card-text>
              {{ dialogModal.text }}
            </v-card-text>

            <v-card-actions>
              <v-spacer />

              <v-btn
                color="red darken-1"
                text
                @click="dialogModal.open = false"
              >
                {{ $t('actions.disagree') }}
              </v-btn>

              <v-btn
                color="green darken-1"
                text
                @click="dialogModal.open = false; changeStatus(control.status, control.export);"
              >
                {{ $t('actions.agree') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogAppend.open"
          max-width="1200"
          persistent
        >
          <v-card min-height="800">
            <v-card-title class="text-h5">
              {{ dialogAppend.headline }}
            </v-card-title>

            <v-card-text style="min-height: 715px !important">
              <v-toolbar
                dark
                color="primary"
              >
                <v-text-field
                  v-model="search"
                  class="ma-2"
                  :label="$t('actions.search')"
                  dark
                  flat
                  solo-inverted
                  hide-details
                  clearable
                  clear-icon="mdi-close-circle-outline"
                />
              </v-toolbar>
              <v-row>
                <v-col>
                  <v-card-text>
                    <v-treeview
                      v-if="!loading && loadedInvoices"
                      v-model="tree"
                      style="cursor: pointer"
                      activatable
                      selectable
                      color="primary"
                      selected-color="indigo"
                      return-object
                      open-on-click
                      transition
                      :multiple-active="false"
                      :items="items"
                      :search="search"
                      expand-icon="mdi-chevron-down"
                      indeterminate-icon="mdi-bookmark-minus"
                      :open.sync="openNodes"
                      @update:active="activateTreeItem"
                    >
                      <template #prepend="{ item }">
                        <v-icon>
                          {{ item.children ? `mdi-folder-network` : `mdi-file` }}
                        </v-icon>
                      </template>
                    </v-treeview>
                  </v-card-text>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-card-text>
                    <div
                      v-if="tree.length === 0"
                      key="title"
                      class="text-h6 font-weight-light grey--text pa-4 text-center"
                    >
                      {{ $t('actions.append') }}
                    </div>

                    <v-scroll-x-transition
                      group
                      hide-on-leave
                    >
                      <v-chip
                        v-for="(selection, i) in tree"
                        :key="i"
                        color="grey"
                        dark
                        small
                        class="ma-1"
                      >
                        <v-icon
                          left
                          small
                        >
                          mdi-beer
                        </v-icon>
                        {{ selection.name }}
                      </v-chip>
                    </v-scroll-x-transition>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-spacer />

              <v-btn
                color="red darken-1"
                text
                @click="dialogAppend.open = false"
              >
                {{ $t('actions.disagree') }}
              </v-btn>

              <v-btn
                color="green darken-1"
                text
                @click="addAdditionalDocs"
              >
                {{ $t('actions.agree') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-card
          elevation="0"
          style="height: 80vh; overflow-y: auto; overflow-x: hidden"
        >
          <v-alert
            v-if="!loading && loadedInvoices"
            elevation="1"
            prominent
            border="left"
            icon="mdi-alert-octagon-outline"
            :color="setParameter(invoices.status.value,'bg-color bg-yellow ft-color ft-white', 'bg-color bg-blue ft-color ft-white', 'bg-color bg-dk-blue ft-color ft-white') "
            :type="setParameter(invoices.status.value,'success', 'warning', 'error') "
            class="ma-2"
          >
            {{ translate(invoices.status.value) }}
          </v-alert>
          <invoice-extraction-list
            v-if="loadedInvoices"
            ref="invoice-extraction-list"
            :invoices="invoices"
            class=""
            @change:oin="getInvoice"
            @change:dateDelivery="getInvoice"
            @file-moved="onChangeContractType"
            @loaded="loading = false"
          />
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-card height="100%">
            <vue-pdf-app :pdf="pdf" :config="configPdf" theme="light" />
        </v-card>
      </v-col>
    </v-row>
    <div
      v-if="!loading && loadedInvoices"
      class="buttons d-flex"
    >
      <v-btn
        id="button-left"
        :disabled="!prevInvoice"
        elevation="1"
        large
        class="ma-2 bg-color bg-blue ft-color ft-white"
        @click="showInvoice(prevInvoice)"
      >
        <v-icon large>
          mdi-menu-left
        </v-icon>
      </v-btn>

      <v-btn
        id="button-accept"
        elevation="1"
        large
        class="ma-2 bg-color bg-yellow ft-color ft-white"
        @click="control.status = 2; control.export = false; dialogModal.headline = $t('invoices.dialog.accept'); dialogModal.text = $t('invoices.dialog.acceptLong')"
        @click.stop="dialogModal.open = true"
      >
        <v-icon class="mr-2">
          mdi-check
        </v-icon>
        {{ $t('invoices.buttons.accept') }}
      </v-btn>
            
      <v-btn
        id="button-accept-export"
        elevation="1"
        large
        class="ma-2 bg-color bg-blue ft-color ft-white"
        @click="control.status = 2; control.export = true; dialogModal.headline = $t('invoices.dialog.acceptExport'); dialogModal.text = $t('invoices.dialog.acceptExportLong')"
        @click.stop="dialogModal.open = true"
      >
        <v-icon class="mr-2">
          mdi-export
        </v-icon>
        {{ $t('invoices.buttons.acceptExport') }}
      </v-btn>
        
          
      <v-btn
        id="button-right"
        :disabled="!nextInvoice"
        elevation="1"
        large
        class="ma-2 bg-color bg-blue ft-color ft-white"
        @click="showInvoice(nextInvoice)"
      >
        <v-icon large>
          mdi-menu-right
        </v-icon>
      </v-btn>

      <v-btn
        id="button-append"
        elevation="1"
        large
        class="ma-2 bg-color bg-dk-blue ft-color ft-white ml-auto"
        @click="dialogAppend.open = true"
      >
        <v-icon large>
          mdi-plus
        </v-icon>
        {{ $t('invoices.buttons.append') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import InvoiceExtractionList from "@/components/invoice/InvoiceExtractionList";
import axios from "axios";
import api from "@/resources/axios.json";
import invoiceData from '@/resources/invoice.json';
import _ from "lodash";
import services from '@/services/ServiceMethods';
import Vue from "vue";
import i18n from "@/i18n";
import VuePdfApp from "vue-pdf-app";
// import this to use default icons for buttons
import "vue-pdf-app/dist/icons/main.css";

export default {
  name: "InvoiceCard",
  components: {
    InvoiceExtractionList,
    VuePdfApp 
  },
  data() {
    return {
      openNodes: [],
      disabledAdditional: false,
      loading: true,
      loadedInvoices: false,
      pdf: null,
      pdfName: null,
      invoices: {},
      nextInvoice: null,
      prevInvoice: null,
      multiLine: true,
      color: 'i-y',
      control: {
        status: null,
        export: false,
      },
      dialogModal: {
        headline: null,
        text: null,
        open: false
      },
      dialogAppend: {
        headline: null,
        text: null,
        open: false
      },
      api: api,
      selected: null,
      tree: [],
      open: [],
      search: null,
      active: [],
      items: [{
        id: 0,
        name: "",
        children: []
      }],
      treeIdIterator: 1,
      sharepointPath: "/",
      contractType: 2,
      
      configPdf: {
        toolbar: {
          toolbarViewerRight: {
            presentationMode: true,
            openFile: true,
            print: true,
            download: true,
            viewBookmark: false,
        },
        }
      },
    };
  },
  async created() {
    this.loading = true;
    await this.getInvoice();
    await this.getNextPrevInvoice();
    this.loadedInvoices = true;
    
    this.items[0].name = this.$store.state.client.folder_path;
    this.$emit("changeClient", this.$store.state.client.organization, this.$store.state.client.oin);
    this.activateTreeItem(this.items);

    window.addEventListener("keydown", this.arrowEventListener);
  },
  destroyed() {
    window.removeEventListener("keydown", this.arrowEventListener);
  },
  methods: {
    arrowEventListener(e) {
      let code = e.which || e.keyCode;
      if(code === 37) {            //left arrow
        this.showInvoice(this.prevInvoice);
      }
      else if(code === 39) {       //right arrow
        this.showInvoice(this.nextInvoice);
      }
    },
    async onChangeContractType(filePlace) {
      if (filePlace.id === "income") {
        this.$refs["invoice-extraction-list"].$refs["invoice-panel-section-additional"].obj.internal_number.value = this.numberIterator(this.$store.state.invoiceInformation.latestInternalNumber);
        const internalNumber = this.numberIterator(this.$store.state.invoiceInformation.latestInternalNumber);
        this.invoices.additional.internal_number.value = internalNumber;
        this.invoices.internal_number = internalNumber;
        this.contractType = 2;
        this.invoices.generals.contract_type = { id: 2, value: filePlace.id };
        await this.$store.dispatch("setInvoiceInformation", {key: "contractType", value: { id: 2, value: filePlace.id }});
      } else if (filePlace.id === "expense") {
        this.$refs["invoice-extraction-list"].$refs["invoice-panel-section-additional"].obj.internal_number.value = this.invoices.generals.contract_number.value;
        this.invoices.additional.internal_number.value = this.invoices.generals.contract_number.value;
        this.invoices.internal_number = this.invoices.generals.contract_number.value;
        this.contractType = 1;
        this.invoices.generals.contract_type = { id: 1, value: filePlace.id };
        await this.$store.dispatch("setInvoiceInformation", {key: "contractType", value: { id: 1, value: filePlace.id }});
      } else {
        this.$refs["invoice-extraction-list"].$refs["invoice-panel-section-additional"].obj.internal_number.value = this.invoices.generals.contract_number.value;
        this.invoices.additional.internal_number.value = this.invoices.generals.contract_number.value;
        this.invoices.internal_number = this.invoices.generals.contract_number.value;
        this.contractType = 3;
        this.invoices.generals.contract_type = { id: 3, value: filePlace.id };
        await this.$store.dispatch("setInvoiceInformation", {key: "contractType", value: { id: 3, value: filePlace.id }});
      }

      const webUrlResponse = await services.get(api.get.fileWebUrl + this.invoices.file.id);
      this.$refs["invoice-extraction-list"].$refs["invoice-panel-section-additional"].obj.file_weburl.value = webUrlResponse ? webUrlResponse.data.file_weburl : "";
    },
    setParameter(condition, paramA, paramB, paramC) {
      if (condition !== undefined) {
        switch (condition) {
          case 'ACCEPTED':
            return paramA;
          case 'RETRAIN':
            return paramB;
          default:
            return paramC;
        }
      }
    },
    translate(v) {
      return this.$t('invoices.statuses.' + v);
    },
    setValueFromCombobox(value) {
      if (value && typeof value === 'object') {
        return value.text;
      } else {
        return value;
      }
    },
    addAdditionalDocs() {
      this.dialogAppend.open = true;
      this.mergeDocuments();
    },
    findParents(arr, id) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id === id) {
          return [];
        } else if (arr[i].children && arr[i].children.length) {

          let t = this.findParents(arr[i].children, id);

          if (t !== false) {
            t.push(arr[i].name);

            return t;
          }
        }
      }
      return false;
    },
    async activateTreeItem(item) {

      if ( item && item[0] && item[0].name && item.length !== 0) {
        if (item[0].children === undefined) {
          return false;
        }

        this.sharepointPath = '/' + item[0].name;
        if (this.sharepointPath === '/') {
          this.sharepointPath = "";
        } else {
          let pathParts = this.findParents(this.$store.state.documentTree, item[0].id);
          this.sharepointPath = "/";
          for (let i = pathParts.length - 1; i >= 0; i--) {
            this.sharepointPath += '/' + pathParts[i];
          }
          this.sharepointPath += '/' + item[0].name;
        }

        const response = await services.post(api.post.documentStructure, {path: this.sharepointPath}, false);

        let documentTree = response ? response.data : null;
        item[0].children = this.createDocumentStructure(documentTree);
        await this.$store.dispatch(`setDocumentTree`, this.items);
        this.sharepointPath = "";

        this.openNodes.push(item[0]);
      }
    },
    createDocumentStructure(structure) {

      let struct = [];
      let it = 0;

      if (structure !== undefined) {
        for (let j = 0; j < structure.folders.length; j++) {
          struct[it] = {id: this.treeIdIterator, name: structure.folders[j], children: []};
          this.treeIdIterator++;
          it++;
        }

        for (let k = 0; k < structure.files.length; k++) {
          struct[it] = {id: this.treeIdIterator, name: structure.files[k]};
          this.treeIdIterator++;
          it++;
        }

        if (structure.folders.length === 0) {
          return struct;
        }

        return struct;
      } else {
        return [];
      }
    },
    mergeDocuments() {

      let toBeMerged = [];
      let invoiceId = this.$route.params.id;

      for (let i = 0; i < this.tree.length; i++) {

        if (this.tree[i].children === undefined) {
          let pathParts = this.findParents(this.$store.state.documentTree, this.tree[i].id);

          this.sharepointPath = "/";
          for (let i = pathParts.length - 1; i >= 0; i--) {
            this.sharepointPath += '/' + pathParts[i];
          }
          this.sharepointPath += '/' + this.tree[i].name;

          let path = this.sharepointPath;
          toBeMerged.push(path);
        }
      }
      services.post(api.post.mergedDocuments, {
        original: invoiceId,
        others: toBeMerged
      });
      this.dialogAppend.open = false;
    },
    formatDate(date) {
      if (!date) return null;
      if (date.includes(".")) {
        return date.split('.').reverse().join("-");
      }
      return date;
    },
    async changeStatus(status, ex = false) {
      const generals = this.$refs["invoice-extraction-list"].$refs["invoice-panel-section-generals"].obj;
      const recipient = this.$refs["invoice-extraction-list"].$refs["invoice-panel-section-recipient"].obj;
      const contractor = this.$refs["invoice-extraction-list"].$refs["invoice-panel-section-contractor"].obj;
      const additional = this.$refs["invoice-extraction-list"].$refs["invoice-panel-section-additional"].obj;
      const generalsFormValid = this.$refs["invoice-extraction-list"].$refs["invoice-panel-section-generals"].$refs["form"].validate();
      const recipientFormValid = this.$refs["invoice-extraction-list"].$refs["invoice-panel-section-recipient"].$refs["form"].validate();
      const contractorFormValid = this.$refs["invoice-extraction-list"].$refs["invoice-panel-section-contractor"].$refs["form"].validate();
      const additionalFormValid = this.$refs["invoice-extraction-list"].$refs["invoice-panel-section-additional"].$refs["form"].validate();

      if (generalsFormValid && recipientFormValid && contractorFormValid && additionalFormValid) {

        if (!this.disabledAdditional) {
          let payload = {};
          payload.id_invoice = this.$route.params.id;
          payload.recipient = {};
          payload.contractor = {};

          payload.recipient.name = recipient.name.value;
          payload.recipient.oin = recipient.oin.value;
          payload.recipient.vat = recipient.vat_number.value;
          payload.contractor.name = contractor.name.value;
          payload.contractor.oin = contractor.oin.value;
          payload.contractor.vat = contractor.vat_number.value;

          for (let key in additional) {
            payload[key] = this.setValueFromCombobox(additional[key].value);
          }

          await services.post(api.post.companies, payload);
          this.disabledAdditional = true;
        }

        let payloadInternal = {
          recipient: {
            oin: null,
          },
          contractor: {
            oin: null,
          },
          internal_number: null,
          file_id: null
        };

        payloadInternal.recipient.oin = recipient['oin'].value;
        payloadInternal.contract_type = generals['contract_type'].id;
        payloadInternal.contractor.oin = contractor['oin'].value;
        payloadInternal.internal_number = additional['internal_number'].value;
        payloadInternal.file_id = this.invoices.file.id;
        payloadInternal.file_imported = this.formatDate(additional['file_imported'].value);
        payloadInternal.vat_application = this.formatDate(additional['vat_application'].value);
        payloadInternal.accounting_case = this.formatDate(additional['accounting_case'].value);
        payloadInternal.invoice_id = this.$route.params.id;

        await services.put(api.put.saveInvoiceDates, payloadInternal, false);

        await services.put(api.put.internalNumber, payloadInternal, false);

        const invoiceStatusResponse = await services.put(api.put.invoiceStatusChange + this.$route.params.id, {
          status: status,
          user_id: this.$store.state.user.oid,
          contractor: contractor['oin'].value,
          recipient: recipient['oin'].value,
          internal_number: additional['internal_number'].value
        }, true);

        if (invoiceStatusResponse.status === 200) {
          await services.put(api.put.renameFile + this.invoices.id, {invoice_id: this.invoices.id}, false);
        }

        const selectedInvoice = {};
        const system = this.invoices.client.acc_system.account_system;

        selectedInvoice[system] = {ids: [parseInt(this.$route.params.id)]};

        if (ex) {
          const headers = {
            'x-access-token': localStorage.getItem('adal.idtoken'),
            'Accept': 'application/json',
            responseType: 'blob'
          };

          await axios.post(api.post.export,
              {
                selected: selectedInvoice,
                type: this.contractType,
                identity: this.$store.state.client.oin
              }, {headers}).then((response) => {

            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', Object.keys(selectedInvoice)[0] + '_import.xml');

            document.body.appendChild(fileLink);

            fileLink.click();
          });
        }
      } else {
        Vue.$toast(i18n.t('actions.errorHandle.errorForm'), {
          toastClassName: "danger-class",
        });
      }
    },
    async setInternalNumber(invoiceData) {
      const response = (await services.get(api.get.latestInternalNumber));
      const latestInternalNumber = response ? response.data.internal_number : null;
      await this.$store.dispatch("setInvoiceInformation", {key: "latestInternalNumber", value: latestInternalNumber});

      if (invoiceData && invoiceData.generals.contract_type.id !== 2) {
        return invoiceData.generals.contract_number.value;
      } else {
        if (invoiceData.status.value === 'NEW' && invoiceData.file.file_internal_name === null) {
          return this.numberIterator(latestInternalNumber);
        } else {
          if (invoiceData && invoiceData.internal_number) {
            return invoiceData.internal_number;
          } else {
            return this.$store.state.invoiceInformation.latestInternalNumber;
          }
        }
      }
    },
    async getInvoice() {
      let invoiceResponse = await services.get(api.get.invoiceById + this.$route.params.id);
      if(!invoiceResponse) {
        return null;
      } else {
        invoiceResponse = invoiceResponse.data;
      }

      const client = invoiceResponse.client;
      await this.$store.dispatch(`setLatestClient`, client);
      this.$emit("changeClient", client.organization, client.oin);

      await this.$store.dispatch("setInvoiceInformation", {key: "accountSystem", value: {id: invoiceResponse.client.acc_system.id, value: invoiceResponse.client.acc_system.account_system}});
      let contractTypeObj = invoiceResponse.generals && invoiceResponse.generals.contract_type ? 
        {
    
                id: invoiceResponse.generals.contract_type.id, 
                value: invoiceResponse.generals.contract_type.value 
        } :
        {
    
                id: 3, 
                value: "not_sorted" 
            
        };
      this.contractType = contractTypeObj.id;

      await this.$store.dispatch(
        "setInvoiceInformation", 
        {
            key: "contractType", 
            value: 
            { 
                id: contractTypeObj.id, 
                value: contractTypeObj.value 
            }
        });

      if(invoiceResponse.document_type) {
        await this.$store.dispatch("setInvoiceInformation", {key: "documentType", value: { id: invoiceResponse.document_type.id, value: invoiceResponse.document_type.document_type }});
      }

      if (invoiceResponse.file) {
        this.pdf = invoiceResponse.file.file_encoded;
        this.pdfName = invoiceResponse.generals && invoiceResponse.generals.contract_number ? invoiceResponse.generals.contract_number.value : "123456";
      }

      const createdAt = invoiceResponse.created_at;
      let dateDelivery = invoiceResponse.generals && invoiceResponse.generals.date_delivery ?  invoiceResponse.generals.date_delivery.value : null;
      const applicationVatDate = this.getApplicationVatDate(createdAt,dateDelivery);
      const vatApplicationDate = invoiceResponse.vat_application ? invoiceResponse.vat_application.split('-').reverse('-').join('.') : null;
      const accountingCaseDate = invoiceResponse.accounting_case ? invoiceResponse.accounting_case.split('-').reverse('-').join('.') : null;
      const fileImportedDate = invoiceResponse.file_imported ? invoiceResponse.file_imported.split('-').reverse('-').join('.') : null;

      delete invoiceResponse.vat_application;
      delete invoiceResponse.accounting_case;
      delete invoiceResponse.file_imported;
      delete invoiceResponse.file.file_encoded;
      delete invoiceResponse.created_at;
      delete invoiceResponse.updated_at;

      this.invoices = invoiceResponse;
      this.invoices.generals = {'document_type': invoiceData.generals.document_type , ...this.invoices.generals};

      let companiesResult = null;
      let companiesResponseStatus = null;

      if (this.invoices.recipient.oin.value !== null && this.invoices.contractor.oin.value !== null) {
        companiesResult = await services.get(api.get.companiesByOin + (this.invoices.recipient.oin.value).replace(/\s/g, '') + "&" + (this.invoices.contractor.oin.value).replace(/\s/g, ''));

        if(!companiesResult) {
          return null;
        } else {
          companiesResponseStatus = companiesResult.status;
          companiesResult = companiesResult.data;
        }
      }

      let additionalData = _.cloneDeep(invoiceData.additional);
      additionalData.internal_number.value = await this.setInternalNumber(invoiceResponse);
      additionalData.vat_application.value = vatApplicationDate || applicationVatDate;
      additionalData.accounting_case.value = accountingCaseDate || applicationVatDate;
      additionalData.file_imported.value = fileImportedDate || createdAt.split("T")[0].split("-").reverse().join(".");
      additionalData.file_weburl.value = this.invoices.file.file_weburl;

      if (companiesResponseStatus === 204) {
        this.disabledAdditional = false;
      } else {
        this.disabledAdditional = true;

        if (companiesResult !== null) {
          additionalData.text.value = companiesResult[0].text ? companiesResult[0].text.text : "";
          additionalData.bef_cont.value = companiesResult[0].bef_cont ? companiesResult[0].bef_cont.value : "";
          additionalData.vat_dp.value = companiesResult[0].vat_dp ? companiesResult[0].vat_dp.vat_dp : "";
          additionalData.vat_kv.value = companiesResult[0].vat_kv ? companiesResult[0].vat_kv.vat_kv : "";
          additionalData.account.value = companiesResult[0].account ? companiesResult[0].account.account : "";

          for (let key in additionalData) {
            additionalData[key].disabled = true;
          }
        } else {
          additionalData.text.value = "";
          additionalData.bef_cont.value = "";
          additionalData.vat_dp.value = "";
          additionalData.vat_kv.value = "";
          additionalData.account.value = "";
        }
      }

      this.invoices.additional = additionalData;
      this.loadedInvoices = true;
    },
    getApplicationVatDate(createdAt, dateDelivery) {
      let parsedCreatedAt = createdAt.split("T")[0].split('-');
      let lastDayOfMonth = new Date(parsedCreatedAt[0], parseInt(parsedCreatedAt[1]), 0); // last day of month

      let result = [];
      result[0] = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(lastDayOfMonth);
      result[1] = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(lastDayOfMonth);
      result[2] = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(lastDayOfMonth);

      if (dateDelivery) {
        let parsedDateDelivery = dateDelivery.split('.').reverse();
        let dateDeliveryObj = new Date(parsedDateDelivery[0], parsedDateDelivery[1] - 1, parsedDateDelivery[2]);

        if (dateDeliveryObj.getTime() > lastDayOfMonth.getTime()) {
          return dateDelivery;
        }
      }

      return result.join('.');
    },
    numberIterator(value) {
      if (value === null) {
        return value;
      }
      let array = value.split("PF");

      if (array.length === 2) {
        const lengthBefore = array[1].length;
        array[1] = parseInt(array[1]);
        array[1] = array[1] + 1;

        const lengthAfter = array[1].toString().length;

        if (lengthAfter < lengthBefore) {
          let missingLength = lengthBefore - lengthAfter;

          let finalValue = 'PF';
          for (let i = 0; i < missingLength; i++) {
            finalValue += '0';
          }
          finalValue += array[1];
          return finalValue;
        } else {
          return "PF" + array[1].toString();
        }
      } else {
        return value;
      }
    },
    async getNextPrevInvoice() {

      this.nextInvoice = null; // reset
      this.prevInvoice = null; // reset

      const actualInvoice = this.$route.params.id;
      const filteredInvoices = this.$store.getters.getFilteredInvoices;

      let previous = null;
      let next = null;

      for (let item in filteredInvoices) {
        if (parseInt(actualInvoice) === parseInt(filteredInvoices[item])) {
          previous = filteredInvoices[parseInt(item) - 1];
          next = filteredInvoices[parseInt(item) + 1];
        }
      }

      this.nextInvoice = next || null;
      this.prevInvoice = previous || null;
    },
    showInvoice(invoiceId) {
      const sortBy = this.$route.params.sortBy || 'status'; // default sortBy in invoiceList
      const sortDesc = this.$route.params.sortDesc === true; // default sortDesc in invoiceList

      this.$router.push({name: 'Invoice', params: {id: invoiceId, sortBy: sortBy, sortDesc: sortDesc}});
    }
  }
};
</script>
<style scoped lang="scss">
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

@media screen and (max-width: 959px) {
  .buttons {
    display: initial !important;

    #button-append {
      margin-left: 0.5rem !important;
    }
  }
}

@media screen and (max-width: 823px) {
  .buttons {
    display: flex !important;
    flex-direction: column;

    #button-left {
      order: 1;
    }
    #button-right {
      order: 2;
    }
    #button-accept {
      order: 3;
    }
    #button-accept-export {
      order: 4;
    }
    #button-append {
      order: 5;
      margin: 0.5rem !important;
    }
  }
}
</style>
